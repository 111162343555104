
<div class="modal jsModal jsDepositModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <form autocomplete="off" class="needs-validation" ngNativeValidate (ngSubmit)="checkForDepositAlerts()">
          <div class="modal-header">
            <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
              <span *ngIf="type == 'credit' && !payment">Receive Payment</span> 
              <span *ngIf="type == 'debit' && !payment">Make Payment</span>
              <span *ngIf="payment">Update Payment</span>
            </h4>
          </div>
          <div class="modal-body">                        
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <app-customer-selector *ngIf="!payment" userType="customer" [selectedUser]="selectedUser" (onCustomerSelected)="setCustomer($event)"></app-customer-selector>
                    <div class="form-group" *ngIf="payment">

                      <a (click) = "showStatement(selectedUser.customer.customer)">
                        <h3>{{selectedUser.customer.user.name}}</h3>
                        <small>{{selectedUser.customer.user.email}}</small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Amount Due:</label>
                    <input class="form-control" disabled="disabled" type="text" value="{{totalBalance}}" />                     
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Amount Settled<span class="text-danger">*</span>:</label>
                    <div class="input-group">

                      <input class="form-control" type="text" placeholder="Enter amount" id="depositAmount" name="depositAmount" [(ngModel)] = "depositModel.amount" />   
                      <div class="input-group-addon" *ngIf="depositModel.amount && bookingsList.length > 0 && !payment" (click)="reflectInPaymentField()">Reflect in Payment field</div>                  
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group" *ngIf="bookingsList.length > 0 && type == 'credit'">
                    <label>Associate Payment to Bookings:</label>
                    <div class="row" style="margin-left: 1px;">
                      <!-- <div class="col-lg-1">
                        
                      </div> -->
                      <div class="col-lg-2">
                        <strong>Booking #</strong> 
                      </div>
                      <div class="col-lg-1">
                        <strong>PNR</strong> 
                      </div>
                      <div class="col-lg-3">
                        <strong>Travel Date</strong> 
                      </div>
                      <div class="col-lg-2">
                        <strong>Amount</strong>
                      </div>
                      <div class="col-lg-2">
                        <strong>Amount Due</strong>
                      </div>
                      <div class="col-lg-2">
                        <strong>Payment</strong>
                      </div>
                    </div>                     
                    <div class="row" style="margin-left: 1px;" *ngFor="let booking of bookingsList; let i=index">
                      <!-- <div class="col-lg-1">
                        <input type="checkbox" class="jsCheckbox jsBooking" id="depositModel.bookings[i]" value="{{booking.id}}" (change)="setBookingId($event)" name="depositModel.bookings[i]" /> 
                      </div> -->
                      <div class="col-lg-2">
                        {{booking.booking_id}}
                      </div>
                      <div class="col-lg-1">
                        {{booking.pnr}}
                      </div>
                      <div class="col-lg-3">
                        {{booking.departure_date_time}}
                      </div>
                      <div class="col-lg-2">
                        {{booking.amount}}
                      </div>
                      <div class="col-lg-2">
                        INR {{booking.balance}} /-
                      </div>
                      <div class="col-lg-2">
                        <input type="text" class="form-control input-sm" name="payment_amount_{{i}}" [(ngModel)] = "depositModel.bookings[i].payment_amount" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-payment-method [selectedPaymentMethod]="selectedPaymentMethod" (onMethodSelected)="setPaymentMethod($event)"></app-payment-method>
              
                  <app-accounts [label]="accountSelectLabel" [selectedAccount]="selectedAccount" (onMethodSelected)="setAccount($event)"></app-accounts>
                  
                  <div class="row" style="margin-left: 1px;">
                    <div class="col-lg-6 col-sm-12">
                      <div class="form-group">
                        <label>Payment Date:</label>
                        <input class="form-control" type="date" name="paymentDate" [(ngModel)] = "depositModel.payment_date"/>                     
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <div class="form-group">
                        <label>Transaction ID:</label>
                        <input class="form-control" type="text" placeholder="Transaction ID" id="transactionId" name="transactionId" [(ngModel)] = "depositModel.transaction_id" />                     
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Remarks<span class="text-danger">*</span>:</label>
                    <textarea class="form-control" placeholder="Enter remarks" id="depositRemarks" name="depositRemarks" [(ngModel)] = "depositModel.remarks"></textarea>                     
                  </div>
                  <!-- /.form-group -->
                </div>
                <div class="col-lg-6">
                  <div class="dashed-border clearfix">
                    <div class="row">
                      <div class="col-lg-6">
                        <strong  *ngIf="type == 'credit'">Amount Received:</strong>
                        <strong  *ngIf="type == 'debit'">Amount Paid:</strong>
                      </div>
                      <div class="col-lg-6">
                        {{depositModel.amount | number : '1.2-2'}}
                      </div>
                    </div>
                    <div class="row" *ngIf="type == 'credit'">
                      <div class="col-lg-6">
                        <strong>Amount used for Payments:</strong>
                      </div>
                      <div class="col-lg-6">
                        {{amountUsedForPayments() | number : '1.2-2'}}
                      </div>
                    </div>
                    <div class="row" *ngIf="bookingsList.length > 0 && type == 'credit'">
                      <div class="col-lg-6">
                        <strong>Amount in Excess:</strong>
                      </div>
                      <div class="col-lg-6">
                        {{(depositModel.amount - amountUsedForPayments()) | number : '1.2-2'}}
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger pull-left" data-dismiss="modal" *ngIf="payment" (click)="showDeleteWarning()"><i aria-hidden="true" class="fa fa-trash"></i> Delete Payment</button>
            <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
            <button type="submit" class="btn btn-green"><i class="fa fa-check" aria-hidden="true"></i> Deposit</button>
          </div>
        </form>         
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->

  <div class="modal jsModal jsDepositAlertModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-warning">
          <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Warning!</h4>
        </div>
        <div class="modal-body">
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
          <button type="button" class="btn btn-success margin-right-0 jsModalClose" data-dismiss="modal" (click)="depositToAccount()" ><i aria-hidden="true" class="fa fa-check"></i> Yes, continue</button>
          <!-- <button type="button" class="btn btn-green">Copy</button> -->
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  
  <div class="modal jsModal jsDeleteAlertModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-warning">
          <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Warning!</h4>
        </div>
        <div class="modal-body">
          Are you sure you want to delete the payment?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> No</button>
          <button type="button" class="btn btn-success margin-right-0 jsModalClose" data-dismiss="modal" (click)="deletePayment()" ><i aria-hidden="true" class="fa fa-check"></i> Yes, Delete it</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  
  <app-statement-filter [customer]="selectedCustomer" [selectorClass]="'statementFromDeposit'"></app-statement-filter>