import { Component, OnInit } from '@angular/core';
import { ProductEnums } from '../../enums/products.enums';

declare var $: any;

@Component({
  templateUrl: './base.component.html',
})
export class BaseComponent implements OnInit {

  productEnums = {
    flight: ProductEnums.Flight.toString(),
    hotel: ProductEnums.Hotels.toString(),
    transport: ProductEnums.Transport.toString(),
    activity: ProductEnums.Activity.toString(),
    personnel: ProductEnums.Personnel.toString(),
  };
  
  constructor(

  ) {

  }

  ngOnInit(): void {
    this.executeCustomScripts();
  }

  public executeCustomScripts(): void {
    $(document).ready(function(){

      // $('.jsSelectAll').click(function(){
      //   $(this).closest('.table').find('.jsSelect').click();
      // });

      $(".jsSelectAll").on("ifChanged", function(){
  
      });
      
      $('.jsCheckbox').iCheck({
        checkboxClass: 'icheckbox_flat-green',
        radioClass   : 'iradio_flat-green'
      });

      $('.select2').select2();

      $('.jsDate').datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy'
      });       

      $('.jsTime').timepicker({
        showInputs: false
      });

      $('.jsCancel').click(function(){
        window.history.back();
      }); 
      
      $(document).on('click', '.jsModalClose', function (e) {
        $(this).closest('.jsModal').hide();
      });

      $(document).on('change', '.jsFileUploadControl', function (e) {
        $(this).closest('.jsFileUpload').find('p').text(this.files.length + " file(s) selected");
      });      

      initializeAutoComplete();
      initializeDrag();

      function initializeAutoComplete() {
        // Toggle dropdown menu on click of trigger element
        $(document).on('click', '.autocomplete-text', function (e) {
          // Hide other dropdown menus in case of multiple dropdowns
          $(".autocomplete-list").hide();
          $(this).parent().find(".autocomplete-list").show();                     
        });

        // Hide dropdown menu on click outside
        $(document).on("click", function(event){
          if(!$(event.target).closest(".autocomplete-text").length){
              $(".autocomplete-list").hide();            
          }
        });

      }

      function initializeDrag() {
        $( ".jsDraggable" ).draggable({
          //revert: "invalid",
          //helper: 'clone'
        });
      }

      // formValidations();

      // function formValidations() {
      //   (function() {
      //     'use strict';
      //     window.addEventListener('load', function() {
      //       // Get the forms we want to add validation styles to
      //       var forms = document.getElementsByClassName('needs-validation');
      //       // Loop over them and prevent submission
      //       var validation = Array.prototype.filter.call(forms, function(form) {
      //         form.addEventListener('submit', function(event) {
      //           if (form.checkValidity() === false) {
      //             event.preventDefault();
      //             event.stopPropagation();
      //           }
      //           form.classList.add('was-validated');
      //         }, false);
      //       });
      //     }, false);
      //   })();
      // }   
      
    });
  }

}
