import { Injectable } from '@angular/core';
import { APIHelperService } from './api-helper.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  httpHeaders: any;

  constructor(
    private apiHelperService: APIHelperService
  ) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'accept': 'application/json',
      })
    };
  }

  public getAccounts(query?: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/expenses/accounts`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result.data);
            observer.complete();
          }
        );
      }
    );
  }

  public createAccount(accountModel: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/expenses/accounts`;
      this.apiHelperService.post(uri, accountModel, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public deleteAccount(accountModel: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/expenses/accounts/${accountModel.id}`;
      this.apiHelperService.delete(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
}
