import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { AuthResponseModel,UserModel } from '../account/account.model';
import { BaseComponent } from '../shared/components/base/base.component'

declare var $: any;
@Component({
  templateUrl: './header.component.html',
  selector: '<app-header></app-header>',
})
export class HeaderComponent extends BaseComponent implements OnInit {
  user: UserModel;
  authResponse: AuthResponseModel = new AuthResponseModel();
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    // To call the base class member we use super
    super();
    super.ngOnInit();

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  ngOnInit(): void {
    

  }

  logout(): void {
    this.authService.logout();
  }

}
