import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel, UserModel } from '../../account/account.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { CustomerSearchModel, PasswordLessLoginModel, CustomersListModel, ApproveRevokeModel, CustomerModel, TransactionsListModel, ApproveRejectTransactionModel, ExportToExcelModel, BankTransferModel, DepositModel } from '../../customers/models/customers.model';

@Injectable()
export class CustomersService {
  httpHeaders: any;
  authResponse: AuthResponseModel;
  user: UserModel = new UserModel();
  customersList: CustomersListModel = new CustomersListModel();
  transactionsList: TransactionsListModel = new TransactionsListModel();
  agency: any;

  constructor(
    private apiHelperService: APIHelperService,
    private authService: AuthService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  public approveRevoke(approveRevokeModel: ApproveRevokeModel): Observable<any> {
    return new Observable((observer) => {
      if(approveRevokeModel){ 
        let uri = approveRevokeModel.is_approve ? `${environment.apiURI}/customers/approve/${approveRevokeModel.customer_id}` : `${environment.apiURI}/customers/revoke/${approveRevokeModel.customer_id}` ;
        this.apiHelperService.put(uri, approveRevokeModel, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }
  
  public mergeCustomers(selectedCustomers, primaryMergeCustomer): Observable<any> {
    return new Observable((observer) => {
        let uri = `${environment.apiURI}/customers/merge`;
        this.apiHelperService.post(uri, {
          "selected_customers": selectedCustomers,
          "primary_customer": primaryMergeCustomer
        }, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          ); 
    });
  }

  public approveRejectTransaction(approveRejectTransactionModel: ApproveRejectTransactionModel): Observable<any> {
    return new Observable((observer) => {
      if(approveRejectTransactionModel){ 
        let uri = `${environment.apiURI}/customers/transaction-status-change/${approveRejectTransactionModel.transaction_id}`;
        this.apiHelperService.post(uri, approveRejectTransactionModel, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public createCustomer(customerModel: CustomerModel): Observable<any> {
    return new Observable((observer) => {
      if(customerModel){ 
        let uri =  `${environment.apiURI}/customers/customer`;
        this.apiHelperService.post(uri, customerModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public updateCustomer(customerModel: CustomerModel): Observable<any> {
    return new Observable((observer) => {

      if(customerModel){ 
        let uri =  `${environment.apiURI}/customers/customer/${customerModel.id}`;
        this.apiHelperService.put(uri, customerModel, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public getCustomers(pageNo: any ,customerSearch: any = null): Observable<any> {
    return new Observable((observer) => {
      let strurl;
      
      strurl = `${environment.apiURI}/customers/list`;
      
      let uri = strurl;
      uri = uri + pageNo;
      this.apiHelperService.post(uri, customerSearch, this.httpHeaders)
        .subscribe(
          response => { 
            this.convertResponseToCustomersList(response);
            observer.next(this.customersList);
            observer.complete();
          }
        );
      }
    );
  }

  public getCustomer(id: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/customer/${id}/edit`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response => {            
            observer.next(this.convertResponseToCustomerModel(response));
            observer.complete();
          }
        );
      }
    );
  }

  public getTransactions(pageNo: any, id: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/customer/${id}/edit`;
      uri = uri + '?page=' + pageNo;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response => { 
            this.convertResponseToTransactionsList(response.transactions);
            observer.next(this.transactionsList);
            observer.complete();
          }
        );
      }
    );
  }
  

  public deleteCustomer(customer: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/customer/${customer.id}`;
      this.apiHelperService.delete(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public loadPendingPaymentBookings(customer: any, paymentId: number = null): Observable<any> {
    return new Observable((observer) => {
      let uri = '';
      if(paymentId) {

         uri = `${environment.apiURI}/customer/load-pending-payment-bookings/${customer}/${paymentId}`;
      } else {
         uri = `${environment.apiURI}/customer/load-pending-payment-bookings/${customer}`;
      }
      
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }

  public loadPdfStatement(generatePdfFilter): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/statement`;
      this.apiHelperService.post(uri, generatePdfFilter, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }

  public deleteCreditDetail(transaction: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/delete-transaction/${transaction.id}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public updateTransactionDate(transaction: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/customers/update-transaction/${transaction.id}`;
      this.apiHelperService.post(uri, transaction, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  private convertResponseToCustomersList(response: any) : void {
   
    this.customersList = {
      customers: Object.values(response.data),
      total: response.total,
      current_page: response.current_page,
      from: response.from,
      to: response.to,
      per_page: response.per_page,
      links: response.links
    }
  }

  private convertResponseToTransactionsList(response: any) : void {
    this.transactionsList = {
      transactions: Object.values(response.data),
      total: response.total,
      current_page: response.current_page,
      from: response.from,
      to: response.to,
      per_page: response.per_page
    }
  }

  private convertResponseToCustomerModel(response: any) : CustomerModel {
    let customerModel = new CustomerModel();
    customerModel = {
      id: response.customer.id,
      address: response.customer.user.address,
      agency_name: response.customer.user.agency_name,
      credit_limit: response.customer.credit_limit,
      due_after:response.customer.due_after,
      email: response.customer.user.email,
      name: response.customer.user.name,
      phone: response.customer.user.phone,
      next_due_date: response.customer.next_due_date,
      is_agency: response.customer.customer_type == 'b2b' ? 1 : 0,
      make_supplier: response.customer.supplier == null ? false : true,
      supplier_margin: response.customer.supplier ? response.customer.supplier.margin : 0,
      balance: response.customer.balance,
      remaining_credit_limit: response.customer.remaining_credit_limit,
      alternate_emails: response.customer.alternate_emails,
      alternate_phone_numbers: response.customer.alternate_phone_numbers,
      user: response.customer.user,
      customer_type: response.customer.customer_type,
      parent_customer_id: response.customer.parent_customer_id,
      opening_balance: response.customer.opening_balance ? (response.customer.opening_balance.debit ? response.customer.opening_balance.debit : response.customer.opening_balance.credit) : 0,
      opening_balance_type: response.customer.opening_balance && response.customer.opening_balance.credit ? 'credit' : 'debit'
    }
    return customerModel;
  }

  public exportToExcel(exportToExeclModel: ExportToExcelModel, customer: CustomerModel): Observable<any> {
    return new Observable((observer) => {
      if(exportToExeclModel){ 
        let uri = `${environment.apiURI}/customers/export-customer-transactions/${customer.id}`;
        this.apiHelperService.post(uri, exportToExeclModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(`${environment.apiURI.replace('api/v1','')}${result.download_path}`);
              observer.complete();
            }
          );
      }      
    });
  }
  public transferAmountToBankAccount(bankTransferModel: BankTransferModel): Observable<any> {
    return new Observable((observer) => {
      if(bankTransferModel){ 
        let uri = `${environment.apiURI}/customer/create/transaction/${bankTransferModel.customer_id}`;
        this.apiHelperService.post(uri, bankTransferModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }
  
  public depositToAccount(depositModel: DepositModel): Observable<any> {

    return new Observable((observer) => {
      if(depositModel){ 
        let uri = `${environment.apiURI}/customer/create/transaction/${depositModel.customer_id}`;
        this.apiHelperService.post(uri, depositModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public getAgencyDetail(): Observable<any> {

    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/agency`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }

  public loginToCustomerPortal(agency, customer): Observable<any> {

    let passwordLessLogin = new PasswordLessLoginModel;
    passwordLessLogin.portal_login_key = agency.portal_login_key;

    return new Observable((observer) => {
      let uri = `${environment.protocol}${agency.domain}/api/v1/passwordless-login/${customer.id}`;

      this.apiHelperService.post(uri, passwordLessLogin, this.httpHeaders)
        .subscribe(
          
          result => {
            window.open(result.url);
            console.log(result.url);
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }


   // Returns an observable
   upload(file):Observable<any> {
    // Make http post request over api
    // with formData as req
    return new Observable((observer) => {
       // Create form data
      const formData = new FormData(); 
      
      // Store form name as "file" with file data
      formData.append("file", file, file.name);
      let httpHeaders = {
        headers: new HttpHeaders(
          {
            'accept': 'application/json',
        })
      };
      this.apiHelperService.post(`${environment.apiURI}/customers/import-customers`, formData, httpHeaders)
      .subscribe(
        result => { 
          observer.next(result);
          observer.complete();
        }
      );
    });
  }
}

