import { Injectable } from '@angular/core';
import { HttpHeaders  } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel, UserModel } from '../../account/account.model';
import { map, debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FlightsModel, FlightTicketsModel, FlightSearchModel, FlightSearchResultsModel, FlightSeriesModel, LoadFlightsModel, LoadFlightResultsModel } from 'src/app/products/models/flights.model';
import { ProductEnums } from '../enums/products.enums';
import { TransportComponentsModel, TransportModel } from 'src/app/products/models/transport.model';
import { ActivityComponentsModel, ActivityModel } from 'src/app/products/models/activity.model';
import { PersonnelComponentsModel, PersonnelModel } from 'src/app/products/models/personnel.model';
import { HotelModel } from 'src/app/products/models/hotel.model';
import { ProductListModel, ProductSearchModel } from 'src/app/products/models/productList.model';
import { AuthService } from './auth.service';
import { LocationModel } from 'src/app/products/models/locations.model';
import { MediaModel } from '../components/media/media.model';


@Injectable()
export class ProductsService {
  httpHeaders: any;
  authResponse: AuthResponseModel;
  user: UserModel = new UserModel();
  productList: ProductListModel = new ProductListModel();
  flightModel: FlightsModel = new FlightsModel();
  transportModel: TransportModel = new TransportModel();
  activityModel: ActivityModel = new ActivityModel();
  personnelModel: PersonnelModel = new PersonnelModel();
  hotelModel: HotelModel = new HotelModel();

  productEnums = {
    flight: ProductEnums.Flight.toString(),
    hotel: ProductEnums.Hotels.toString(),
    transport: ProductEnums.Transport.toString(),
    activity: ProductEnums.Activity.toString(),
    personnel: ProductEnums.Personnel.toString(),
  };

  constructor(
    private apiHelperService: APIHelperService,
    private authService: AuthService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  public addEditFlight(flight: FlightsModel): Observable<any> {
    return new Observable((observer) => {
      if(flight.id == null){ // add
        let uri = `${environment.apiURI}/products/flight`;
        this.apiHelperService.post(uri, flight, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/flight/${flight.id}`;
        this.apiHelperService.put(uri, flight, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public addFlightSeries(flightSeries: FlightSeriesModel): Observable<any> {
    return new Observable((observer) => {
        let uri = `${environment.apiURI}/products/flights/add-series`;
        this.apiHelperService.post(uri, flightSeries, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );      
    });
  }

  public updateFlightSeries(loadFlightResultsModel: LoadFlightResultsModel[]): Observable<any> {
    return new Observable((observer) => {
        let uri = `${environment.apiURI}/products/flights/update-series`;
        this.apiHelperService.post(uri, loadFlightResultsModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );      
    });
  }

  public addEditMedia(category: any, media: any): Observable<any> {
    this.httpHeaders = {
      headers: new HttpHeaders({})
    };
    return new Observable((observer) => {
      if(category == this.productEnums.hotel.toString()){ // hotel media
        let uri = `${environment.apiURI}/products/save-media`;
        this.apiHelperService.post(uri, media, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }     
    });
  }

  public addEditHotel(hotel: HotelModel): Observable<any> {
    return new Observable((observer) => {
      if(hotel.id == null){ // add
        let uri = `${environment.apiURI}/products/hotels`;
        this.apiHelperService.post(uri, hotel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/hotels/${hotel.id}`;
        this.apiHelperService.put(uri, hotel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public addEditTransport(transport: TransportModel): Observable<any> {
    return new Observable((observer) => {
      if(transport.id == null){ // add
        let uri = `${environment.apiURI}/products/vehicle`;
        this.apiHelperService.post(uri, transport, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/vehicle/${transport.id}`;
        this.apiHelperService.put(uri, transport, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public addEditActivity(activity: ActivityModel): Observable<any> {
    return new Observable((observer) => {
      if(activity.id == null){ // add
        let uri = `${environment.apiURI}/products/activity`;
        this.apiHelperService.post(uri, activity, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/activity/${activity.id}`;
        this.apiHelperService.put(uri, activity, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public addEditPersonnel(personnel: PersonnelModel): Observable<any> {
    return new Observable((observer) => {
      if(personnel.id == null){ // add
        let uri = `${environment.apiURI}/products/personnel`;
        this.apiHelperService.post(uri, personnel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/personnel/${personnel.id}`;
        this.apiHelperService.put(uri, personnel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public batchUpdateFlight(flightSearchResults: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/update-bulk-price`;
      this.apiHelperService.post(uri, flightSearchResults, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );           
    });
  }

  public getProducts(category: string, pageNo: any, productSearchModel: ProductSearchModel = new ProductSearchModel): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/list`;
      uri = uri + '?page=' + pageNo;
      if (category){
        uri = uri + '&product_category=' + category;
      }
      this.apiHelperService.post(uri, productSearchModel, this.httpHeaders)
        .subscribe(
          response => {
            this.convertResponseToProductsList(response);
            observer.next(this.productList);
            observer.complete();
          }
        );
      }
    );
  }

  public loadPrices(id: string): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/hotels/load-prices/${id}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response.success);
            observer.complete();
          }
        );
      }
    );
  }

  public searchFlights(flightSearch: FlightSearchModel): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/public/${this.user.agency_id}/search-flights`;
      this.apiHelperService.post(uri, flightSearch, this.httpHeaders)
        .subscribe(
          flights => {
            observer.next(flights);
            observer.complete();
          }
        );
      }
    );
  }

  public loadFlights(loadFlightsModel: LoadFlightsModel): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/list-series`;
      this.apiHelperService.post(uri, loadFlightsModel, this.httpHeaders)
        .subscribe(
          results => {
            observer.next(this.convertResponseToLoadFlightResultsModel(results));
            observer.complete();
          }
        );
      }
    );
  }

  public loadFlight(flightId: number, queryParams): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/load/${flightId}?adults=${queryParams.adult}&infants=${queryParams.infant}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          flight => {
            observer.next(flight);
            observer.complete();
          }
        );
      }
    );
  }

  public getProduct(category: string, id: string): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/`;
      if(category == this.productEnums.flight)
        uri = uri + 'flight/' + id + '/edit';
      else if(category == this.productEnums.transport)
        uri = uri + 'vehicle/' + id + '/edit';
      else if(category == this.productEnums.activity)
        uri = uri + 'activity/' + id + '/edit';
      else if(category == this.productEnums.personnel)
        uri = uri + 'personnel/' + id + '/edit';
      else if(category == this.productEnums.hotel)
        uri = uri + 'hotels/' + id + '/edit';

      this.apiHelperService.get(uri, this.httpHeaders)
        .pipe(
          map(
            (response: any) => {
              if(category == this.productEnums.flight) {
                this.convertResponseToFlight(response);
                observer.next(this.flightModel);                
              } else if(category == this.productEnums.transport) {
                this.convertResponseToTransport(response);
                observer.next(this.transportModel);                
              } else if(category == this.productEnums.hotel) {
                  this.convertResponseToHotel(response);
                  observer.next(this.hotelModel);                
              } else if(category == this.productEnums.activity) {
                this.convertResponseToActivity(response);
                observer.next(this.activityModel);                
              } else if(category == this.productEnums.personnel) {
                this.convertResponseToPersonnel(response);
                observer.next(this.personnelModel);                
              }                          
              observer.complete();           
            }
          ),          
        ).subscribe();
      }
    );      
  }

  public loadAirlines(text: string, isLoading: Boolean = true): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/load-airlines${text  != null ? '/'+ text : ''}`;
      this.apiHelperService.get(uri, this.httpHeaders, isLoading)
        .pipe(
          //debounceTime(500),  // WAIT for KEY STROKE.
          map(
            (response: any) => {
              let result = [];
              response.forEach(function (object) {
                result.push({'label': object.name, 'id': object.id, 'iata_code': object.iata_code })
              });  
              
              observer.next(result);
              observer.complete();
              //console.log(result);            
            }
          ),          
        ).subscribe();
      }
    );      
  }

  public getLocations(text: string, isLoading: Boolean = true): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/public/location${text  != null ? '/'+ text : ''}`;
      this.apiHelperService.get(uri, this.httpHeaders, isLoading)
        .pipe(
          //debounceTime(500),  // WAIT for KEY STROKE.
          map(
            (response: any) => {
              let result = [];
              response.forEach(function (object) {
                result.push({'id': object.id, 'name': object.name })
              });  
              
              observer.next(result);
              observer.complete();
              //console.log(result);            
            }
          ),          
        ).subscribe();
      }
    );      
  }

  public loadAirports(text: string, isLoading: Boolean = true): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/load-airports${text ? '/'+ text : ''}`;
      this.apiHelperService.get(uri, this.httpHeaders, isLoading)
        .pipe(
          //debounceTime(500),  // WAIT For KEY STROKE.
          map(
            (response: any) => {
              let results = [];
              response.forEach(function (object) {
                results.push({'label': object.name + ' - ' + object.cityCode, 'id': object.id })
              });             
              //console.log(results);
              observer.next(results);
              observer.complete();
            }
          ),                    
        ).subscribe();
      }
    );
  }

  public loadSectors(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/load-sectors`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .pipe(
          //debounceTime(500),  // WAIT For KEY STROKE.
          map(
            (response: any) => {
              observer.next(response);
              observer.complete();
            }
          ),                    
        ).subscribe();
      }
    );
  }
  
  public loadFlightSeriesList(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flight`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .pipe(
          //debounceTime(500),  // WAIT For KEY STROKE.
          map(
            (response: any) => {
              observer.next(response);
              observer.complete();
            }
          ),                    
        ).subscribe();
      }
    );
  }

  public deleteProduct(product: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/`;
      if (product.type == ProductEnums.Flight)
        uri = uri + 'flight/' + product.id;
      else if (product.type == ProductEnums.Transport)
        uri = uri + 'vehicle/' + product.id;
      else if (product.type == ProductEnums.Personnel)
        uri = uri + 'personnel/' + product.id;
      else if (product.type == ProductEnums.Activity)
        uri = uri + 'activity/' + product.id;
      else if (product.type == ProductEnums.Hotels)
        uri = uri + 'hotels/' + product.id;
      uri = uri + 'flight/' + product.id;
      this.apiHelperService.delete(uri, this.httpHeaders)
        .subscribe(
          products => {
            observer.next(products);
            observer.complete();
          }
        );
      }
    );
  }
  
  public deleteDistribution(id: any): Observable<any> {
    return new Observable((observer) => {
        let uri = `${environment.apiURI}/products/flights/`;
        uri = uri + 'delete-destribution/' + id;
        this.apiHelperService.get(uri, this.httpHeaders).subscribe(
          products => {
            observer.next(products);
            observer.complete();
          }
        );
      }
    );
  }
  
  public disableProduct(product: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/change-series-status/${product.id}/disable`;
     
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          products => {
            observer.next(products);
            observer.complete();
          }
        );
      }
    );
  }
  public toggleFlightTicketAirlineStatus(flightTicketId: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/toggle-flight-ticket-airline-status/${flightTicketId}`;
     
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          status => {
            observer.next(status);
            observer.complete();
          }
        );
      }
    );
  }
  
  public enableProduct(product: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/flights/change-series-status/${product.id}/enable`;
     
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          products => {
            observer.next(products);
            observer.complete();
          }
        );
      }
    );
  }

  private convertResponseToFlight(response: any) : void {
    let flightTickets = [];
    this.flightModel = {
      id: response.id,
      flight_ticket_destination_id: response.id,
      flight_ticket_destination_name: response.name,
      from_airport_id: response.from_airport_id,
      to_airport_id: response.to_airport_id,
      flight_tickets: [],
      supplier_id: response.supplier_id,
      supplier: response.supplier,
      fromSearchString: response.from_airport.cityName + ' - ' + response.from_airport.cityCode,
      toSearchString: response.to_airport.cityName + ' - ' + response.to_airport.cityCode,
      deleted:[],
      adults: null,
      infants: null,
      departureDate: null,
      allow_past_booking: false
    }
    response.flight_tickets.forEach(function (object) {
      let flightTicket = new FlightTicketsModel();
      flightTicket = {
        id: object.id,
        airline_id: object.airline_id,
        number_of_tickets: object.number_of_tickets,
        airline_code: object.airline_code,
        arrival_date_time: object.arrival_date_time,
        departure_date_time: object.departure_date_time,
        price: object.product.price.price,
        buying_price: object.product.buying_price,
        pnr: object.pnr,
        number_of_sold_tickets : object.number_of_sold_tickets,
        searchString: object.airline.name,
        note: object.note,
        infant_price: object.infant_price,
        from_terminal: object.from_terminal,
        to_terminal: object.to_terminal,
        distribution: object.distribution,
      }
      flightTickets.push(flightTicket);
    });  
    this.flightModel.flight_tickets = flightTickets;                            
  }

  private convertResponseToLoadFlightResultsModel(response: any) : any {
    let loadFlightResults = [];    
    response.forEach(function (object) {
      let loadFlightResultsModel = new LoadFlightResultsModel();
      loadFlightResultsModel = {
        id: object.id,
        airline: object.airline.name,
        arrival_date_time: object.arrival_date_time,
        departure_date_time: object.departure_date_time,
        pnr: object.pnr,
        number_of_sold_tickets : object.number_of_sold_tickets,
        price: object.product.price.price,
        ticket_count: object.number_of_tickets,
        note: object.note,
        to_terminal: object.to_terminal,
        from_terminal: object.from_terminal,
        infant_price: object.infant_price,
        airline_code: object.airline_code
      }
      loadFlightResults.push(loadFlightResultsModel);
    });  
    return loadFlightResults;                            
  }

  private convertResponseToHotel(response: any) : void {
    this.hotelModel = response;
    this.hotelModel.deleted = [];
    this.hotelModel.location.locationSearchString = response.location.name;                        
  }

  private convertResponseToTransport(response: any) : void {
    let transportModel = new TransportModel();
    transportModel = {
      id: response.id,
      name: response.name,
      capacity: response.capacity,
      components: new TransportComponentsModel()
    }  

    response.components.forEach(function (object) {
      if(object.component == 'sight_seeing')
        transportModel.components.sight_seeing = object.product.price.price;
      else if(object.component == 'drop')
        transportModel.components.drop = object.product.price.price;
      else if(object.component == 'fixed')
        transportModel.components.fixed = object.product.price.price;
    });   
    
    this.transportModel = transportModel;
  }

  private convertResponseToPersonnel(response: any) : void {
    let personnelModel = new PersonnelModel();
    personnelModel = {
      id: response.id,
      name: response.name,
      category: response.category,
      description: response.description,
      components: new PersonnelComponentsModel()
    }  

    response.components.forEach(function (object) {
      if(object.component == 'hourly')
        personnelModel.components.hourly = object.product.price.price;
      else if(object.component == 'fixed')
        personnelModel.components.fixed = object.product.price.price;
    });   
    
    this.personnelModel = personnelModel;
  }

  private convertResponseToActivity(response: any) : void {
    let activityModel = new ActivityModel();
    activityModel = {
      id: response.id,
      name: response.name,
      description: response.description,
      exclusions: response.exclusions,
      inclusions: response.inclusions,
      location: response.location,
      needs_vehicle: response.needs_vehicle,
      hour_time:response.hour_time,
      components: new ActivityComponentsModel()
    }  
    activityModel.location.locationSearchString = response.location.name;

    response.components.forEach(function (object) {
      if(object.component == 'hourly')
        activityModel.components.hourly = object.product.price.price;
      else if(object.component == 'hourly_per_person')
        activityModel.components.hourly_per_person = object.product.price.price;
      else if(object.component == 'fixed')
        activityModel.components.fixed = object.product.price.price;
      else if(object.component == 'fixed_per_person')
        activityModel.components.fixed_per_person = object.product.price.price;
    });   
    
    this.activityModel = activityModel;
  }

  private convertResponseToProductsList(response: any) : void {
    this.productList = {
      products: Object.values(response.data),
      total: response.total,
      current_page: response.current_page,
      from: response.from,
      to: response.to,
      per_page: response.per_page
    }
  }

   // Returns an observable
   upload(file):Observable<any> {

   
    // Make http post request over api
    // with formData as req
    return new Observable((observer) => {
       // Create form data
      const formData = new FormData(); 
      
      // Store form name as "file" with file data
      formData.append("file", file, file.name);
      let httpHeaders = {
        headers: new HttpHeaders(
          {
            'accept': 'application/json',
        })
      };
      this.apiHelperService.post(`${environment.apiURI}/products/import-inventory`, formData, httpHeaders)
      .subscribe(
        result => { 
          observer.next(result);
          observer.complete();
        }
      );
    });
  }
}
