import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './loading.component.html',
  selector: '<app-loading></app-loading>',
})
export class LoadingComponent implements OnInit {

  constructor(

  ) {
  }

  ngOnInit(): void {

  }

}
