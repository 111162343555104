export class TransportModel {
    id: string = null;
    name: string;
    capacity: string;
    components?: TransportComponentsModel;

    constructor() {
        this.components = new TransportComponentsModel();
    }
}

export class TransportComponentsModel {    
    drop: string;
    sight_seeing: string;
    fixed: string;
}





