export class FlightsModel {
    id: string = null;
    flight_ticket_destination_id: string;
    flight_ticket_destination_name: string;
    from_airport_id: string;
    to_airport_id: string;
    supplier_id?: string;
    supplier?: any = [];
    flight_tickets: FlightTicketsModel[] = [];
    fromSearchString?: string;
    toSearchString?: string;
    deleted: string[] = [];
    adults: number = 1;
    infants: number = 0;
    departureDate: Date = null;
    allow_past_booking: Boolean = false;
    constructor() {
        this.flight_tickets.push(new FlightTicketsModel());
    }
}

export class FlightTicketsModel {
    id: string = null;
    airline_id: string;
    airline_code: string;
    departure_date_time: string;
    arrival_date_time: string;
    number_of_tickets: string;
    price: string;
    arrivalDate?: string;
    arrivalTime?: string;
    departureDate?: string;
    departureTime?: string;
    searchString?: string;
    pnr: string = null;
    number_of_sold_tickets: number = null;
    note: any = null;
    infant_price: any = 1500;
    from_terminal: string = null;
    to_terminal: string = null;
    buying_price: string = null;
    distribution: FlightTicketDistribution[] = [];
}

export class FlightTicketDistribution {
    id: string;
    published_with: string;
    number_of_tickets: number;
}

export class FlightSearchModel {
    from_airport_id: string;
    to_airport_id: string;
    date: string;
    adults: null;
    infants: null;
}

export class FlightSearchResultsModel {
    product_id: string;
    airline: string;
    time: Date;
    price: number;
}

export class FlightSeriesModel {
    id: string = null;
    supplier_id: string;
    name: string;
    from_date: string;
    to_date: string;
    from_airport_id: string;
    from_terminal: string;
    to_terminal: string;
    to_airport_id: string;
    airline_id: string;
    airline_code: string;
    arrival_time?: string;
    departure_time?: string;
    ticket_count: string;
    price: string;
    infant_price: number = 1500;
    buying_price: string;
    next_day_arrival: string;
    fromSearchString?: string;
    toSearchString?: string;
    airlineSearchString?: string;    
}

export class LoadFlightsModel {
    from_date: string;
    to_date: string;
    airline_code: string;
}

export class LoadFlightResultsModel {
    id: string;    
    ticket_count: number;
    pnr: string = null;
    number_of_sold_tickets: number = null;
    price: number;
    airline: string;
    airline_code: string;
    departure_date_time: string;
    arrival_date_time: string;
    note: string;
    infant_price: number;
    from_terminal: string;
    to_terminal: string;
}



