import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { AuthResponseModel, UserModel } from '../account/account.model';
import { BaseComponent } from '../shared/components/base/base.component'

declare var $: any;
@Component({
  templateUrl: './menu.component.html',
  selector: '<app-menu></app-menu>',
})
export class MenuComponent extends BaseComponent implements OnInit {
  userName: string;
  authResponse: AuthResponseModel = new AuthResponseModel();
  authResponses : any;
  permissions : any;
  user_type = '';
  inventory=false;
  dashboard=false;
  flightbookings=false;
  payments=false;
  customers=false;
  users=false;
  reports=false;
  expenses=false;
  router: Router;
  user: UserModel;
  mainRoute: string;
  
  constructor(
    router: Router,
    private authService: AuthService
  ) {
    // To call the base class member we use super
    super();
    super.ngOnInit();

    this.authResponse = authService.getAuthResponse();
    this.authResponses = authService.getAuthResponse();

    this.userName = this.authResponse.user != null ? this.authResponse.user.name : '';
	this.router = router;
	this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  ngOnInit(): void {
	this.mainRoute = this.router.url.split('/')[1];
	var myThis = this;
    if(this.authResponses){
		this.permissions = this.authResponses.user.permissions;
		this.user_type = this.authResponses.user.menuroles;
		if(this.user_type != "admin"){
			this.permissions.forEach(function(item){

				if(item.name == 'dashboard'){
					myThis.dashboard = true;
				}
				if(item.name == 'inventory'){
					myThis.inventory = true;
				}
				if(item.name == 'flightbookings'){
					myThis.flightbookings = true;
				}
				if(item.name == 'payments'){
					myThis.payments = true;
				}
				if(item.name == 'customers'){
					myThis.customers = true;
				}
				if(item.name == 'users'){
					myThis.users = true;
				}
				if(item.name == 'reports'){
					myThis.reports = true;
				}
				if(item.name == 'expenses'){
					myThis.expenses = true;
				}
			})
		}else{
			myThis.dashboard = true;
			myThis.inventory = true;
			myThis.flightbookings = true;
			myThis.payments = true;
			myThis.customers = true;
			myThis.users = true;
			myThis.reports = true;
			myThis.expenses = true;
		}
		
	}

  }
  logout(): void {
    this.authService.logout();
  }

}
