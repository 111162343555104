import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIHelperService } from './api-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  httpHeaders: any;
  constructor(
    private apiHelperService: APIHelperService
  ) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'accept': 'application/json',
      })
    };
  }

  public loadAgencyDetail(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/get-agency`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public loadAgencySettings(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/website`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public loadAgencyBankDetail(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/bank`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public loadBanks(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/load-banks`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public saveAgencyDetail(company): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/agency`;
      this.apiHelperService.post(uri, company, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public saveBank(bankDetails): Observable<any> {
    return new Observable((observer) => {
      let uri = '';
      if(bankDetails.id) {
         uri = `${environment.apiURI}/settings/bank/${bankDetails.id}`;
         this.apiHelperService.put(uri, bankDetails, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      } else {
         uri = `${environment.apiURI}/settings/bank`;
         this.apiHelperService.post(uri, bankDetails, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
      }
      
    );
  }
  public loadBank(id): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/bank/${id}/edit`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public deleteBank(id): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/bank/${id}`;
      this.apiHelperService.delete(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public updateSetting(setting): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/settings/update-setting`;
      this.apiHelperService.post(uri, setting, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public getLogo(): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/public/show-logo`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
}
