import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AuthService } from './services/auth.service';
import { SubscriptionService } from './services/subscription.service';
import { APIHelperService } from './services/api-helper.service';
import { GeneralMethodsService } from './services/general-methods.service';
import { LoadingComponent } from './components/loading/loading.component';
import { BaseComponent } from './components/base/base.component';
import { AlertComponent } from './components/alert/alert.component';
import { CustomerSelectorComponent } from './components/customer-selector/customer-selector.component';
import { AlertService } from './services/alert.service';
import { ProductsService } from './services/products.service';
import { PackagesService } from './services/packages.service';
import { ErrorInterceptor  } from '../shared/components/interceptors/error.interceptor';
import { JwtInterceptor  } from '../shared/components/interceptors/jwt.interceptor';
import { BookingsService } from './services/bookings.service';
import { CustomersService } from './services/customers.service';
import { SettingsService } from './services/settings.service';
import { DepositComponent } from '../shared/components/deposit/deposit.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { PaymentService } from './services/payment.service';
import { StatementFilterComponent } from './components/statement-filter/statement-filter.component';
import { AgencyService } from './services/agency.service';
import { AccountsComponent } from './components/accounts/accounts.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
    ],
    providers: [
        SubscriptionService,
        APIHelperService,
        AuthService,
        AlertService,
        GeneralMethodsService,
        ProductsService,
        PackagesService,
        BookingsService,
        CustomersService,
        SettingsService,
        PaymentService,
        AgencyService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      ],
    declarations: [
        LoadingComponent,
        BaseComponent,
        AlertComponent,
        CustomerSelectorComponent,
        DepositComponent,
        PaymentMethodComponent,
        StatementFilterComponent,
        AccountsComponent,
    ],
    exports: [
        LoadingComponent,
        BaseComponent,
        AlertComponent,
        CustomerSelectorComponent,
        HttpClientModule,
        FormsModule,
        CommonModule,
        DepositComponent,
        PaymentMethodComponent,
        StatementFilterComponent
    ]

})
export class SharedModule { }