import { LocationModel } from './locations.model';

export class HotelModel {
    id: string = null;
    name: string;
    category: string;
    location: LocationModel = new LocationModel();
    description: string;
    address: string;
    rooms?: HotelRoomsModel[] = [];
    deleted: string[] = [];    

    constructor() {
        this.rooms.push(new HotelRoomsModel());
    }
}

export class HotelRoomsModel {  
    id: string = null;  
    title: string;
    pricing: HotelRoomPricingModel = new HotelRoomPricingModel();
}

export class HotelRoomPricingModel {    
    wom: HotelRoomPricingOptionsModel = new HotelRoomPricingOptionsModel();
    map: HotelRoomPricingOptionsModel = new HotelRoomPricingOptionsModel();
    cp: HotelRoomPricingOptionsModel = new HotelRoomPricingOptionsModel();
    ep: HotelRoomPricingOptionsModel = new HotelRoomPricingOptionsModel();
}

export class HotelRoomPricingOptionsModel {    
    room: string;
    extra_bed: string;
    cwb: string;
    cwob: string;
}





