export class ProductListModel {
    products: any[] = [];
    total: number;
    current_page: number;
    from: number;
    to: number;
    per_page: number;
}


export class ProductSearchModel {
    pnr: string;
    name: string;
    fromSearchString: string;
    from_airport_id: number;
    toSearchString: string;
    to_airport_id: number;
    airline_id: number;
    departure_date: string;
    departure_time: number;
}


