import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, flatMap, } from 'rxjs/operators';

declare var $: any;

@Injectable()
export class APIHelperService {

    constructor(
        private http: HttpClient
    ) { }

    public post(url: string, body: any, httpHeaders: any): Observable<any> {
        this.showLoading();
        return this.http.post<any>(url, body, httpHeaders).pipe(
            tap( response => {
                //console.log("response", response);
                this.hideLoading();

            }),
            catchError(this.handleError)
        );
    }

    public put(url: string, body: any, httpHeaders: any): Observable<any> {
        this.showLoading();
        return this.http.put<any>(url, body, httpHeaders).pipe(
            tap( response => {
                //console.log("response", response);
                this.hideLoading();                
                
            }),
            catchError(this.handleError)
        );
    }

    public get(url: string, httpHeaders: any, isLoading: Boolean = true): Observable<any> {
        if(isLoading)
                this.showLoading();
        return this.http.get<any>(url, httpHeaders).pipe(
            tap( response => {
                 //console.log("response", response);
                if(isLoading)
                    this.hideLoading();
            }),
            catchError(this.handleError)
        );    
    }

    public delete(url: string, httpHeaders: any): Observable<any> {
        this.showLoading();
        return this.http.delete<any>(url).pipe(
            tap( response => {
                 //console.log("response", response);
                 this.hideLoading();
            }),
            catchError(this.handleError)
        );      
    }

    private handleError(error: HttpErrorResponse) {

        $('.jsLoading').addClass('hide').removeClass('show');  // hide loading

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        } 
        // window.alert(errorMessage);
        return throwError(error);
    }

    public showLoading(message=null) {
        if(message) {
            $('.jsLoading .loading-text').html(message);
        }
        $('.jsLoading').addClass('show').removeClass('hide');
        $('.jsLoading').css('top', $(window).scrollTop() + 'px');
    }

    public hideLoading() {
        $('.jsLoading').addClass('hide').removeClass('show');
    }

}
