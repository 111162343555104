import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SubscriptionService } from '../services/subscription.service';


@Injectable({ providedIn: 'root' })
export class SubscriptionGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private subscription: SubscriptionService,
    ) {}

    // tslint:disable-next-line: typedef
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(localStorage.getItem('isActive'));
        if(!localStorage.getItem('isActive')) {
            
            this.subscription.hasActiveSubscription()
            .subscribe(
                isActive => {
                    localStorage.setItem('isActive', isActive);
                    if (!isActive) {
                        // authorised so return true
                        this.router.navigate(['/subscription/detail']);
                        return false;
                    } 
                }
            );
        } else {
            if(JSON.parse(localStorage.getItem('isActive')) == false) {
                this.router.navigate(['/subscription/detail']);
                return false;
            } 
            
        }
        
        return true;
    }
}