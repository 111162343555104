export class AccountModel {
    username: string = "";
    password: string = "";
}

export class TokenModel {
    auth_token: string;
    token_type: string;
    expires_in: number;
}

export class UserModel {
    id: string;
    agency_id: string;
    name: string;
    address: string;
    email: string;
    email_verified_at: string;
    api_token: string;
    menuroles: string;
    status: string;
    last_signin: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    permissions: any;
}

export class AuthResponseModel {
    token: TokenModel;
    user: UserModel;

    constructor() {
        this.token = new TokenModel(),
        this.user = new UserModel()
    }
}

