<div class="form-group">
    <input type="text" class="form-control customer-search autocomplete-text" id="from" name="from" [(ngModel)] = "searchString"  (ngModelChange) = "this.customerSearchTextSubject.next($event)" (focus) = "this.customerSearchTextSubject.next($event)" placeholder="{{placeholder}}" />
    <div class="list-loading" *ngIf = "isCustomerListLoading">
        <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>
    <div *ngIf = "customersList" class="autocomplete-list jsAutocompleteList">
      <div class="autocomplete-item" (click) = "selectedCustomer(null)">
        None
      </div>
        <div *ngFor = "let customer of customersList">
            
            <div class="autocomplete-item" (click) = "selectedCustomer(customer)">
                <div class="row" style="width: 90%;">
                    <div class="col-lg-6">
                        <p>{{customer.user.email}} | {{customer.user.phone}}</p>
                    </div>
                    <div class="col-lg-5">
                        <p class="pull-right" *ngIf = "customer.agency_code">Agency code: <strong>{{customer.agency_code}}</strong></p>
                    </div>
                </div>
                <div class="row" style="width: 90%;">
                    <div class="col-lg-6">
                        <p><strong>{{customer.user.agency_name ? customer.user.agency_name : customer.user.name}}</strong></p>
                    </div>
                    <div class="col-lg-5">
                        <p class="pull-right text-danger" *ngIf = "'--' != customer.next_due_date">Payment due: <strong>{{customer.next_due_date}}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary" (click) = "openAddNewCustomerForm()" style="position: absolute; top: 0; right: 0; height: 61px; width: 61px;">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </button>
</div>
<div class="modal jsModal jsCreateCustomerModal_{{userType}}" style="top: 0; left: 0;">
    <div class="modal-dialog">
      <div class="modal-content">
        <form autocomplete="off" class="needs-validation" (ngSubmit)="createCustomer()" ngNativeValidate >
          <div class="modal-header">
            <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Add customer</h4>
          </div>
          <div class="modal-body">  
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                    <label>Type:</label>
                    <select class="form-control" id="customer_type" name="customer_type" [(ngModel)] = "customerModel.customer_type">
                      <option value="">Normal</option>
                      <option value="corporate">Corporate</option>
                    </select>                     
                </div>
              </div>
              <div class="col-lg-6" *ngIf="customerModel.customer_type != 'corporate' && corporateCustomers.length > 0">
                <div class="form-group">
                    <label>Corporate customer:</label>
                    <select class="form-control" id="parent_customer_id" name="parent_customer_id" [(ngModel)] = "customerModel.parent_customer_id">
                      <option value=""></option>
                      <option *ngFor="let customer of corporateCustomers" value="{{customer.id}}">{{customer.user.name}}</option>
                    </select>                     
                </div>
              </div>
            </div>                      
            <div class="row">
              <div class="col-lg-12">
               
                <div class="form-group">
                    <label>Name:</label>
                    <input class="form-control" type="text" placeholder="Enter name" id="name" name="name" [(ngModel)] = "customerModel.name" />                     
                </div>
                <div class="form-group">
                    <label>Agency Name:</label>
                    <input class="form-control" type="text" placeholder="Enter agency name" id="agency_name" name="agency_name" [(ngModel)] = "customerModel.agency_name" />                     
                </div>
                <div *ngIf="customerModel.customer_type != 'corporate'">
                  <div class="form-group">
                      <label>Email:</label>
                      <input class="form-control" type="email" placeholder="Enter email" id="email" name="email" [(ngModel)] = "customerModel.email" />                    
                  </div>
                  <div class="form-group">
                    <label>Alternate Emails:</label>
                    <input class="form-control" type="text" placeholder="Enter altername emails" id="alternate_email" name="alternate_emails" [(ngModel)] = "customerModel.alternate_emails" />                    
                    <small>comma saparated emails example: abc@gmail.com, xyz@gmail.com</small>
                  </div>
                  
                  <div class="form-group">
                      <label>Phone:</label>
                      <input class="form-control" type="text" placeholder="Enter phone" id="email" name="phone" [(ngModel)] = "customerModel.phone" />                    
                  </div>
                  <div class="form-group">
                    <label>Alternate Phone Numbers:</label>
                    <input class="form-control" type="text" placeholder="Enter altername phone numbers" id="alternate_phone_numbers" name="alternate_phone_numbers" [(ngModel)] = "customerModel.alternate_phone_numbers" />                    
                    <small>comma saparated phone numbers example: 7*****8585, 8*****8541</small>
                  </div>
                  <div class="form-group">
                      <label>Is supplier:</label>
                      <input type="checkbox" id="is_supplier" name="is_supplier" [(ngModel)] = "customerModel.make_supplier" />                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
            <button type="submit" class="btn btn-green"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
          </div>
        </form>         
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->