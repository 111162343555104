<!-- 
<div class="h-100 d-flex flex-column">
    <div class="row">
        <app-menu></app-menu>
    </div>    
    <div class="row flex-grow-1">
        <router-outlet></router-outlet>
    </div>
</div> -->
<div class="wrapper">
    <app-header></app-header>
    <!-- Left side column. contains the logo and sidebar -->
    <app-menu></app-menu>
  
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
    
    <!-- /.content-wrapper -->

    <app-footer></app-footer>
  
  </div>
  <!-- ./wrapper -->