import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { BankTransferModel, DepositModel } from 'src/app/customers/models/customers.model';
import { AlertService } from '../../services/alert.service';
import { CustomersService } from '../../services/customers.service';
import { PackagesService } from '../../services/packages.service';
import { PaymentService } from '../../services/payment.service';

declare var $: any;

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  @Input('selectedUser')
    selectedUser: any;

  @Input('type')
    type: any = 'credit';
  
  @Input('payment')
    payment: any = null;

  @Output() 
    onDeposit = new EventEmitter<any>();

  customer: any = null;
  bankTransferModel: BankTransferModel = new BankTransferModel();
  depositModel: DepositModel = new DepositModel();
  accountlist:any;
  bookingsList: any = [];
  totalBalance: number = 0;
  selectedPaymentMethod: any;
  selectedCustomer: any;
  selectedAccount: any;
  accountSelectLabel: string;

  constructor(
    private customersService: CustomersService,
    private alertService: AlertService,
    private packagesService: PackagesService,
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.customer = this.selectedUser ? this.selectedUser.customer.customer.id : null;
    this.accountSelectLabel = 'credit' == this.type ? 'Credited to' : 'Debited from';
    this.getListAccounts();
    this.executeScriptOnPage();
    if(this.customer) {
      this.loadPendingPaymentBookings(this.customer);
    }
    if(this.payment) {
      this.depositModel.id = this.payment.id;
      this.depositModel.amount = this.payment.amount;
      this.depositModel.payment_method_id = this.payment.payment_method ? this.payment.payment_method.id : null;
      this.selectedPaymentMethod = this.payment.payment_method;
      this.depositModel.payment_date = this.payment.payment_date;
      this.depositModel.transaction_id = this.payment.transaction_id;
      this.depositModel.remarks = this.payment.customer_transaction.remarks;
      this.depositModel.account = this.payment.customer_transaction.account_transaction ? this.payment.customer_transaction.account_transaction.account_id : null;
      this.selectedAccount = this.payment.customer_transaction.account_transaction ? this.payment.customer_transaction.account_transaction.account : null;
    }
  }

  transferAmountToBankAccount(): void {

    this.bankTransferModel.customer_id = this.customer.id;
    this.customersService.transferAmountToBankAccount(this.bankTransferModel)
      .subscribe(result => {
        this.alertService.success('Transaction successfull!', { keepAfterRouteChange: true, autoClose: true }); 
        $('.jsBankdepositModal').hide();
      });
  }

  checkForDepositAlerts() {
    let depositAmount = this.depositModel.amount ? this.depositModel.amount : 0;
    if((depositAmount-this.amountUsedForPayments()) != 0 && 'credit' == this.type) {
      $('.jsDepositAlertModal .modal-body').html("Would you like to store the excess amount of <strong>INR " + (depositAmount-this.amountUsedForPayments()) + "</strong> as over payment from this customer?");
      $('.jsDepositAlertModal').show();
    } else {
      this.depositToAccount();
    }
  }

  depositToAccount(): void {

    this.depositModel.customer_id = this.customer;
    this.depositModel.type = this.type;
    this.customersService.depositToAccount(this.depositModel)
      .subscribe(result => {
        $('.jsDepositModal').hide();
        this.alertService.success('Payment received successfully!', { keepAfterRouteChange: true, autoClose: true }); 
        this.onDeposit.emit();
      });
  }

  setCustomer(value): void {

    this.customer = value;
    if(!this.payment) {
      this.loadPendingPaymentBookings(value);
    }
  }

  setBookingId(value: any) {
    this.depositModel.bookings.push(value.currentTarget.value);
  }

  getListAccounts(): void {
    this.packagesService.getList('expenses/accounts')
      .subscribe(result => {
        this.accountlist = (result.data); 
      });
  }

  loadPendingPaymentBookings(customer) {
  
    this.customersService.loadPendingPaymentBookings(customer, this.payment ? this.payment.id : '')
    .subscribe(response => {
      this.depositModel.bookings = response.data;
      this.bookingsList = response.data;
      this.totalBalance = response.total_balance;
      //this.executeScriptOnPage();
    });
  }

  amountUsedForPayments() {
    let totalPayed = 0;
    if(this.depositModel.bookings.length > 0) {
      this.depositModel.bookings.forEach(function(booking){
        totalPayed += booking.payment_amount ? parseInt(booking.payment_amount) : 0;
      });
    }
    return totalPayed;
  }

  reflectInPaymentField() {
    if(this.depositModel.bookings.length > 0) {
      let $this = this;
      let remainingAmount = this.depositModel.amount;
      this.depositModel.bookings.forEach(function(booking, i){
        if(remainingAmount > 0) {
          $this.depositModel.bookings[i].payment_amount = booking.balance >= remainingAmount ? remainingAmount : booking.balance;
          if(booking.balance >= remainingAmount) {
              remainingAmount = 0;
          } else {
              remainingAmount = remainingAmount - booking.balance; 
          }
            
        }
      });
    }
  }

  setPaymentMethod(method) {
    this.depositModel.payment_method_id = method;
  }
  
  setAccount(accountId) {
    this.depositModel.account = accountId;
  }

  showDeleteWarning() {
    $('.jsDeleteAlertModal').show();
  }

  deletePayment() {
    this.paymentService.deletePayment(this.depositModel.id)
    .subscribe(result => {
      $('.jsDepositModal').hide();
      this.alertService.success('Payment deleted successfully!', { keepAfterRouteChange: true, autoClose: true }); 
      this.onDeposit.emit();
    });
  }

  showStatement(customer): void {
    this.selectedCustomer = customer;
    $('.statementFromDeposit').show();
  }

  executeScriptOnPage() {
    $(document).ready(function(){
      $('.jsCheckbox').iCheck({
        checkboxClass: 'icheckbox_flat-green',
        radioClass   : 'iradio_flat-green'
      })
    });
  }
}
