import { LocationModel } from '../../products/models/locations.model';

export class PackageModel {
    id: string = null;
    code: string;
    title: string;
    description: string;
    valid_from: string;
    valid_to: string;
    from_airport_id: string;
    to_airport_id: string;
    number_of_rooms: string;
    adults: string;
    children: string;
    infant: string;
    fromAirportSearchString?: string;
    toAirportSearchString?: string;
    destinations?: PackageDestinationsModel[] = [];
    deleted: string[] = [];

    constructor() {
        this.destinations.push(new PackageDestinationsModel());
    }
}

export class PackageDestinationsModel {  
    id: string = null;  
    location: LocationModel = new LocationModel();
    nights: string;
}





