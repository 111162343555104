<div class="row" style="margin-left: 1px;">
    <div class="col-lg-12">
      <div class="form-group">
        <label>{{label}}:</label>
        <input type="text" class="form-control add-able-search autocomplete-text" name="account" [(ngModel)] = "searchString" (ngModelChange) = "this.methodSearchTextSubject.next($event)" (focus) = "this.methodSearchTextSubject.next($event)"/>
        <div class="list-loading" *ngIf = "isAccountsListLoading">
            <i class="fa fa-circle-o-notch fa-spin"></i>
        </div>
        <div *ngIf = "accountsList" class="autocomplete-list jsAutocompleteList">
          <div class="autocomplete-item" (click) = "selectAccount(null)">
            None
          </div>
            <div *ngFor = "let account of accountsList">
                <div class="autocomplete-item" (click) = "selectAccount(account)">
                    <strong>{{account.name}}</strong><span (click) = "openAddNewAccountForm(method)" class="pull-right" style="color: '#7c1fa3'; font-size: 21px;margin-right:13px;margin-bottom: -1px;"><i class="fa fa-edit"></i></span>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click) = "openAddNewAccountForm()" style="position: absolute; top: 25px; right: 8px; height: 61px; width: 61px;">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  
  <div class="modal jsModal jsCreateAccountModal" style="top: 0; left: 0;">
      <div class="modal-dialog">
        <div class="modal-content">
          <form autocomplete="off" class="needs-validation" (ngSubmit)="createAccount()" ngNativeValidate >
            <div class="modal-header">
              <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">Add Account</h4>
            </div>
            <div class="modal-body">                        
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                      <label>Name:</label>
                      <input class="form-control" type="text" placeholder="Enter name" id="name" name="name" [(ngModel)] = "accountModel.name" required />                     
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="accountModel.id" type="button" class="btn btn-danger pull-left margin-right-0" data-dismiss="modal" (click) = "deleteAccountMethod()"><i aria-hidden="true" class="fa fa-trash"></i>Delete</button>
              <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
              <button type="submit" class="btn btn-green"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
            </div>
          </form>         
        </div>
      </div>
    </div>