import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel, UserModel } from '../../account/account.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { BookingsListModel, ExportToExcelModel, PNRModel,AmendmentModel, InstantBookingModel, BookingSearch, UpdateBookingModel } from '../../bookings/models/bookings.model';
import { FlightsModel } from '../../products/models/flights.model';
import { FlightBookingPessangersModel } from '../../bookings/models/bookings.model';

@Injectable()
export class BookingsService {
  httpHeaders: any;
  authResponse: AuthResponseModel;
  user: UserModel = new UserModel();
  flightBookingList: BookingsListModel = new BookingsListModel();
 // amendmentDetails: AmendmentModel = new AmendmentModel();

  constructor(
    private apiHelperService: APIHelperService,
    private authService: AuthService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  public sendPNR(pnr: PNRModel): Observable<any> {
    return new Observable((observer) => {
      if(pnr){ 
        let uri = `${environment.apiURI}/bookings/send-pnr`;
        this.apiHelperService.post(uri, pnr, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public getFlightBookings(pageNo: any, bookingSearch: BookingSearch): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/flight-bookings`;
      uri = uri + '?page=' + pageNo;
     
      this.apiHelperService.post(uri, bookingSearch, this.httpHeaders)
        .subscribe(
          response => { 
            this.convertResponseToBookingsList(response);
            observer.next(this.flightBookingList);
            observer.complete();
          }
        );
      }
    );
  }

  public getAmendments(bookingId: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/amendments/flight-amendtments`;
      uri = uri;
      uri = uri + '/' + bookingId;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response =>
           {            
            observer.next(this.convertResponseToAmendtmentModel(response));
            observer.complete(); 
          }
        );
      }
    );
  } 
  public loadPdfTicket(bookingId: any): Observable<any> {
    return new Observable((observer) => {

      this.apiHelperService.get(`${environment.apiURI}/bookings/flights/ticket/${bookingId}`, this.httpHeaders)
        .subscribe(
          response =>
           {            
            observer.next(response);
            observer.complete(); 
          }
        );
      }
    );
  } 
  
  public getBooking(bookingId: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/flights/booking`;
      uri = uri;
      uri = uri + '/' + bookingId;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response =>
           {            
            observer.next(response);
            observer.complete(); 
          }
        );
      }
    );
  } 

  public updateAmendment( amendment:AmendmentModel ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/amendments/flight-amendtments`;
      uri = uri;       
      this.apiHelperService.post(uri,amendment, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 
  
  public updateBooking( updateBooking:UpdateBookingModel ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/flights/booking/${updateBooking.bookingId}`;
      uri = uri;       
      this.apiHelperService.post(uri,updateBooking, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 

  public doInstantBooking( instantBookingModel:InstantBookingModel ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/flight-bookings/instant-booking`;
      uri = uri;       
      this.apiHelperService.post(uri,instantBookingModel, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  }
  
  public getAvailabilities( from:String, to:String, allowPastBookings:Boolean ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/load-availability`;
      uri = uri; 
      var sectorDetail = {
        'from_airport_id': from,
        'to_airport_id': to,
        'allow_past_bookings': allowPastBookings
      };
      this.apiHelperService.post(uri, sectorDetail, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 
  
  public cancelBooking( booking_id:string, cancelFormData ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/cancel/${booking_id}`;
      uri = uri;       
      this.apiHelperService.post(uri, cancelFormData, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 
  public searchFlights( flightModel:FlightsModel ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/search?from_arpt=${flightModel.from_airport_id}&to_arpt=${flightModel.to_airport_id}&flight_date=${flightModel.departureDate}&adults=${flightModel.adults}&infants=${flightModel.infants}`;      
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result =>
           {              
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 
  
  public restoreBooking( booking_id:string ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/undo-cancel/${booking_id}`;
      uri = uri;       
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 

  public deleteBooking( booking_id:string ): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/bookings/flight-bookings/delete/${booking_id}`;
      uri = uri;       
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result =>
           {                      
            observer.next(result);
            observer.complete(); 
          }
        );
      }
    );
  } 

  

  public exportToExcel(exportToExeclModel: ExportToExcelModel): Observable<any> { 
    return new Observable((observer) => {
      if(exportToExeclModel){ 
        let uri = `${environment.apiURI}/bookings/export-flight-bookings`;
        this.apiHelperService.post(uri, exportToExeclModel, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(`${environment.apiURI.replace('api/v1','')}${result.download_path}`);
              observer.complete();
            }
          );
      }      
    });
  }

  public bookFlight(flightBookingPassengerModel: FlightBookingPessangersModel, flightId: number): Observable<any> {
    return new Observable((observer) => {
    
        let uri = `${environment.apiURI}/bookings/book/${flightId}`;
        this.apiHelperService.post(uri, flightBookingPassengerModel, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
    });
  }
  public getFlightDetailsFromApi(flightCode): Observable<any> {
    return new Observable((observer) => {
    
        let uri = `${environment.apiURI}/public/get-flight-detail/${flightCode}`;
        this.apiHelperService.get(uri, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
    });
  }
  
  public getAirport(airportCode): Observable<any> {
    return new Observable((observer) => {
    
        let uri = `${environment.apiURI}/public/get-airport/${airportCode}`;
        this.apiHelperService.get(uri, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
    });
  }

  private convertResponseToBookingsList(response: any) : void {

    this.flightBookingList = {
      bookings: Object.values(response.data),
      total: response.total,
      current_page: response.current_page,
      from: response.from,
      to: response.to,
      per_page: response.per_page     
    }
   // alert(this.flightBookingList.bookings[0].amendment.type);
  }

  private convertResponseToAmendtmentModel(response: any) :  AmendmentModel
   {    
      
    let amendmentModel = new AmendmentModel(); 
   
      amendmentModel = {
      flight_booking_id: response.flight_booking_id,
      type: response.type,
      passengers : response.passengers,
     // amount : null;
      reason : response.reason,
      remarks: response.remarks,
      status: response.status   
    }
    return amendmentModel;   
    
  }
  
  
}
