import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel, UserModel } from '../../account/account.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PackageListModel } from 'src/app/packages/models/packageList.model';
import { AuthService } from './auth.service';
import { PackageDestinationsModel, PackageModel } from 'src/app/packages/models/packages.model';
import { LocationModel } from 'src/app/products/models/locations.model';

@Injectable()
export class PackagesService {
  httpHeaders: any;
  authResponse: AuthResponseModel;
  user: UserModel = new UserModel();
  packageList: PackageListModel = new PackageListModel();
  packageModel: PackageModel = new PackageModel();

  constructor(
    private apiHelperService: APIHelperService,
    private authService: AuthService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }

  public addEditPackage(packages: PackageModel): Observable<any> {
    return new Observable((observer) => {
      if(packages.id == null){ // add
        let uri = `${environment.apiURI}/products/package`;
        this.apiHelperService.post(uri, packages, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }
      else { //edit
        let uri = `${environment.apiURI}/products/package/${packages.id}`;
        this.apiHelperService.put(uri, packages, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          ); 
      }        
    });
  }

  public addEvent(event: any): Observable<any> {
    return new Observable((observer) => {
      if(event){ 
        let uri = `${environment.apiURI}/products/package/add-day-event`;
        this.apiHelperService.post(uri, event, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }
  
  public addDayDetails(details: any): Observable<any> {
    return new Observable((observer) => {
      if(details){ 
        let uri = `${environment.apiURI}/bookings/send-pnr`;
        this.apiHelperService.post(uri, details, this.httpHeaders)
          .subscribe(
            result => { 
              observer.next(result);
              observer.complete();
            }
          );
      }      
    });
  }

  public getPackages(pageNo: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/package`;
      uri = uri + '?page=' + pageNo;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response => {
            this.convertResponseToPackageList(response);
            observer.next(this.packageList);
            observer.complete();
          }
        );
      }
    );
  }

  public loadDays(id: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/package/load-days/${id}`;
      this.apiHelperService.post(uri, null, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }
        );
      }
    );
  }

  public getPackage(id: string): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/package/${id}/edit`;

      this.apiHelperService.get(uri, this.httpHeaders)
        .pipe(
          map(
            (response: any) => {
              this.convertResponseToPackage(response);                        
              observer.next(this.packageModel);           
            }
          ),          
        ).subscribe();
      }
    );      
  }

  public deletePackage(Package: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/products/package/${Package.id}`;
      this.apiHelperService.delete(uri, this.httpHeaders)
        .subscribe(
          packages => {
            observer.next(packages);
            observer.complete();
          }
        );
      }
    );
  }

  private convertResponseToPackage(response: any) : void {
    let packageModel = new PackageModel();
    packageModel = {
      id: response.id,
      title: response.title,
      code: response.code,
      valid_from: response.valid_from,
      valid_to: response.valid_to,
      description: response.description,
      adults: response.adults,
      children: response.children,
      infant: response.infant,
      from_airport_id: response.from_airport == null ? '' : response.from_airport.id,
      fromAirportSearchString: response.from_airport == null ? '' : response.from_airport.name,
      to_airport_id: response.to_airport == null ? '' : response.to_airport.id,
      toAirportSearchString: response.to_airport == null? '' : response.to_airport.name,
      number_of_rooms: response.number_of_rooms,
      destinations: [],
      deleted: []
    }  

    response.destinations.forEach(function (object) {
      let destination = new PackageDestinationsModel();
      destination.location = new LocationModel();
      destination = {
        id: object.id,
        location: {
          id: object.location_id,
          name: object.destination,
          locationSearchString: object.destination 
        },        
        nights: object.nights
      };
      packageModel.destinations.push(destination);
    });  
    this.packageModel = packageModel; 
  }

  private convertResponseToPackageList(response: any) : void {
    this.packageList = {
      packages: Object.values(response.data),
      total: response.total,
      current_page: response.current_page,
      from: response.from,
      to: response.to,
      per_page: response.per_page
    }
  }
  
  public getList(url): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/`+url;

      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }
        );
      }
    );
  }
  public post(url,data): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/`+url;
      this.apiHelperService.post(uri, data, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }
        );
      }
    );
  }
  public put(url,data): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/`+url;
      this.apiHelperService.put(uri, data, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }
        );
      }
    );
  }
 
	
	public deleteFromList(url): Observable<any> {
		return new Observable((observer) => {
		  let uri = `${environment.apiURI}/`+url;
		  this.apiHelperService.delete(uri, this.httpHeaders)
			.subscribe(
			  packages => {
				observer.next(packages);
				observer.complete();
			  }
			);
		  }
		);
	}
}
