<div class="modal jsModal" [(ngClass)]="selectorClass">
    <div class="modal-dialog">
        <div class="modal-content">
            <form autocomplete="off" class="needs-validation" ngNativeValidate>
                <div class="modal-header">
                    <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">
                    <span *ngIf="customer && customer.user">Generate Statement of {{customer.user.name}}</span>
                    </h4>
                </div>
                <div class="modal-body">                        

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="pretty p-icon p-curve">
                                    <input type="radio" name="statement_type" value="full_statement" [(ngModel)]="filterModel.statement_type" (click)="setStatementType($event)"/>
                                    <div class="state p-success">
                                      <i class="icon fa fa-check"></i>
                                      <label>Generate full statement</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="pretty p-icon p-curve">
                                    <input type="radio" name="statement_type" value="last_zero" [(ngModel)]="filterModel.statement_type" (click)="setStatementType($event)"/>
                                    <div class="state p-success">
                                      <i class="icon fa fa-check"></i>
                                      <label>Generate statement from last zero balance</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>From Date:</label>
                                <input class="form-control" type="date" name="fromDate" [(ngModel)] = "filterModel.from" [disabled]="filterModel.statement_type" />             
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>To Date:</label>
                                <input class="form-control" type="date" name="toDate" [(ngModel)] = "filterModel.to" [disabled]="filterModel.statement_type"/>             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
                    <button type="submit" class="btn btn-green" (click) = "showStatement('generate')"><i class="fa fa-check" aria-hidden="true"></i> Generate</button>
                    <button type="submit" class="btn btn-danger pull-left" (click) = "confirmSendStatement()"><i class="fa fa-envelope-o" aria-hidden="true"></i> Email to Customer</button>
                </div>
            </form>
        </div>
    </div>
    <div class="modal jsModal jsSendStatementAlert" >
        <div class="modal-dialog">
          <div class="modal-content">
           
              <div class="modal-header modal-header-warning">
                <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Warining</h4>
              </div>
              <div class="modal-body">
                  <h5>Are you sure you want to send statement to customer?</h5>
                  <span *ngIf="customer">Statement will be sent to: <strong>{{customer.user.email}}</strong></span>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Additional Emails</label>
                            <input type="text" class="form-control" [(ngModel)]="generatePdfFilter.additionalEmails" />
                            <small>For multiple additional emails use comma separated emails</small>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Remarks</label>
                            <textarea class="form-control" [(ngModel)]="generatePdfFilter.remarks"></textarea>
                        </div>
                    </div>
                </div>                     
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
                <button type="submit" class="btn btn-green" (click) = "showStatement('email')"><i class="fa fa-check" aria-hidden="true"></i> Yes</button>
              </div>      
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
</div>

