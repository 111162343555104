import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APIHelperService } from './api-helper.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  httpHeaders: any;

  constructor(
    private apiHelperService: APIHelperService
  ) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'accept': 'application/json',
      })
    };
  }

  public createPaymentMethod(paymentMethodModel: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/payment/save-payment-method`;
      this.apiHelperService.post(uri, paymentMethodModel, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  public deletePaymentMethod(paymentMethodModel: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/payment/delete-payment-method/${paymentMethodModel.id}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public getPaymentMethods(query?: any): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/payment/load-payment-methods`;
      this.apiHelperService.post(uri, {'query': query}, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public loadPaymentReport(paymentSearchModel): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/reports/payments`;
      this.apiHelperService.post(uri, paymentSearchModel, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
  
  public loadPaymentDetail(paymentId): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/payments/get-detail/${paymentId}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }

  public deletePayment(paymentId) : Observable<any>  {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/payments/delete/${paymentId}`;
      this.apiHelperService.get(uri, this.httpHeaders)
        .subscribe(
          result => {
            observer.next(result);
            observer.complete();
          }
        );
      }
    );
  }
}
