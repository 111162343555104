import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CustomersService } from '../../services/customers.service';
import { CustomerSearchModel, CustomerModel } from '../../../customers/models/customers.model';
import { AlertService } from '../../services/alert.service';

declare var $: any;

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styles: ['.customer-search { height: 61px; font-size: 20px; cursor:pointer; } .autocomplete-item { border-bottom: solid #ccc 1px; cursor:pointer;}']
})
export class CustomerSelectorComponent implements OnInit {

  @Input('userType')
    userType: string;

  @Input('selectedUser')
    selectedUser: any;

  @Output() 
    onCustomerSelected = new EventEmitter<any>();

  searchString: string;
  selectedCustomerId: number;
  selectedSupplierId: number;
  customerSearchTextSubject = new Subject<string>();
  customersList: any;
  isCustomerListLoading: boolean;
  customerModel: CustomerModel = new CustomerModel();
  placeholder: string = 'Select a Customer';

  corporateCustomers: any = [];

  constructor(
    private customersService: CustomersService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.getCorporateCustomers();
    this.customerSearchTextSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
          .subscribe(value => {
            console.log(value);
              this.isCustomerListLoading = true;
              this.prefillCustomers(this.searchString, false);
      });
      if('supplier' == this.userType) {
        this.placeholder = 'Select a Supplier';
      }

      if(this.selectedUser && this.selectedUser.customer && this.selectedUser.customer.user && this.selectedUser.customer.user.agency_name) {
        this.searchString = this.selectedUser.customer.user.agency_name;
      } else if(this.selectedUser && this.selectedUser.customer && this.selectedUser.customer.user && this.selectedUser.customer.user.name) {
        this.searchString = this.selectedUser.customer.user.name;
      }
  }

  prefillCustomers(text: any, isLoading: Boolean = true): void {
    let customerSearchModel: CustomerSearchModel = new CustomerSearchModel();
    customerSearchModel.query = text;
    customerSearchModel.show = 'supplier' == this.userType ? 'only_suppliers' : null;

    this.customersService.getCustomers('', customerSearchModel)
      .subscribe(customers => {
        if (customers.total == 0)
          return;  
        this.customersList = customers.customers;
        this.isCustomerListLoading = false;
        $(document).ready(function(){
          $('.jsAutocompleteList').each(function(){
            $(this).css('width', $(this).parent().width());
          });
        });      
      });
  }

  selectedCustomer(customer: any) {
    if(customer) {
      this.selectedCustomerId = customer.id;
      this.selectedSupplierId = customer.supplier ? customer.supplier.id : null;
      this.searchString = customer.user.agency_name ? customer.user.agency_name : customer.user.name;
    } else {
      this.selectedCustomerId =null;
      this.selectedSupplierId = null;
      this.searchString = null;
    }
    if('supplier' == this.userType) {
      this.onCustomerSelected.emit(this.selectedSupplierId);
    } else {
      this.onCustomerSelected.emit(this.selectedCustomerId);
    }
  }

  openAddNewCustomerForm(): void {

    $('.jsCreateCustomerModal_'+this.userType).show();
  }

  createCustomer(): void {
    this.customersService.createCustomer(this.customerModel)
      
        .subscribe(
          response => {
            this.alertService.success('Customer added successfully!', { keepAfterRouteChange: true, autoClose: true, });
            $('.jsCreateCustomerModal_'+this.userType).hide();
            this.selectedCustomer(response);
          }
        );
  }

  getCorporateCustomers() {
    let customerSearch = new CustomerSearchModel;
    customerSearch.type = 'corporate';

    this.customersService.getCustomers('?page=1', customerSearch).subscribe(
      response => {
         this.corporateCustomers = response.customers;
      }
    );
  }
}
