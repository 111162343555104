import { Component, OnInit } from '@angular/core';
import { AuthResponseModel } from '../account/account.model';
import { BaseComponent } from '../shared/components/base/base.component'

declare var $: any;
@Component({
  templateUrl: './footer.component.html',
  selector: '<app-footer></app-footer>',
})
export class FooterComponent implements OnInit {
  currentDate: Date;
  authResponse: AuthResponseModel = new AuthResponseModel();
  constructor(

  ) {
    // To call the base class member we use super

    this.currentDate = new Date();
  }

  ngOnInit(): void {
    

  }

}
