<header class="main-header">
  
  <!-- Logo -->
  <a routerLink="/dashboard" class="logo pull-left">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini"><b class="color-pink">T</b><b class="color-green">G</b></span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg">
      <img src="assets/img/tripgoferlogo.png" />
      
    </span>
   

  </a>
  <a class="sidebar-toggle pull-right sm-only" style="padding-top: 0px;" data-toggle="push-menu" role="button">
    <span class="sr-only">Toggle navigation</span>
  </a>
</header>