export class PackageListModel {
    packages: any[] = [];
    total: number;
    current_page: number;
    from: number;
    to: number;
    per_page: number;
}





