import { formatDate } from "@angular/common";

export class CustomersListModel {
    customers: any[] = [];
    total: number;
    current_page: number;
    from: number;
    to: number;
    per_page: number;
    links: any;
}

export class TransactionsListModel {
    transactions: any[] = [];
    total: number;
    current_page: number;
    from: number;
    to: number;
    per_page: number;
}

export class ApproveRevokeModel {
    customer_id: string;
    credit_limit: number;
    is_approve: Boolean;
}

export class ApproveRejectTransactionModel {
    transaction_id: string;
    status: string;
    comment: string;
}

export class CustomerModel {
    id: string;
    is_agency: number;
    make_supplier: boolean;
    supplier_margin: number;
    credit_limit: number;
    next_due_date: string;
    due_after: string;
    name: string;
    agency_name: string;
    address: string;
    email: string;
    phone: string;
    remaining_credit_limit: number;
    balance: number;
    alternate_emails: string;
    alternate_phone_numbers: string;
    customer_type?: string;
    parent_customer_id?: number;
    user: any;
    opening_balance: number;
    opening_balance_type: string = 'debit';
    password?: string;
}

export class ExportToExcelModel {
    start_date: string;
    end_date: string;
}

export class PasswordLessLoginModel {
    portal_login_key: string;
}

export class CustomerSearchModel {
    agency_code: string;
    agency_name: string;
    show: string;
    email: string;
    phone: string;
    type: string;
    query: string;
    parent_customer_id?: any;
}

export class BankTransferModel {
    type: string = 'debit';
    amount: number;
    customer_id: string;
    remarks: string;
}

export class DepositModel {
    id?: any = null;
    date = new Date();
    format = 'yyyy-MM-dd';
    locale = 'en-US';

    type: string = 'credit';
    amount: number;
    customer_id: string;
    payment_method_id: number;
    remarks: string;
    account: string;
    payment_date: string = formatDate(new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()),this.format, this.locale);
    transaction_id: string;
    bookings: any = [];
}
