import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { PaymentService } from '../../services/payment.service';

declare var $: any;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css'],
})
export class PaymentMethodComponent implements OnInit {

  @Input('selectedPaymentMethod')
    selectedPaymentMethod: any = null;

  @Output() 
    onMethodSelected = new EventEmitter<any>();

  searchString: string;
  isPaymentMethodListLoading: boolean;
  paymentMethodsList: any;
  selectedMethodId: number;
  paymentMethodModel: any = {
    "id": null,
    "name": null
  };

  methodSearchTextSubject = new Subject<string>();

  constructor(
    private paymentService: PaymentService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

    if(this.selectedPaymentMethod) {
      this.selectedMethodId = this.selectedPaymentMethod.id;
      this.searchString = this.selectedPaymentMethod.name;
    }
    this.methodSearchTextSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
          .subscribe(value => {
            console.log(value);
              this.isPaymentMethodListLoading = true;
              this.prefillMethods(this.searchString, false);
      });
  }


  prefillMethods(text: any, isLoading: Boolean = true): void {

    this.paymentService.getPaymentMethods(text)
      .subscribe(paymentMethods => {
        this.paymentMethodsList = paymentMethods;
        this.isPaymentMethodListLoading = false;
        $(document).ready(function(){
          $('.jsAutocompleteList').each(function(){
            $(this).css('width', $(this).parent().width());
          });
        });      
      });
  }

  selectedMethod(method: any) {
    if(method) {
      this.selectedMethodId = method.id;
      this.searchString = method.name;
    } else {
      this.selectedMethodId =null;
      this.searchString = null;
    }
    
    this.onMethodSelected.emit(this.selectedMethodId);
  }

  openAddNewPaymentMethodForm(method = null): void {

    if(method)
      this.paymentMethodModel = method;
    else 
      this.paymentMethodModel = {
        "id": null,
        "name": null
      };
    $('.jsCreatePaymentMethodModal').show();
  }

  createPaymentMethod(): void {
    this.paymentService.createPaymentMethod(this.paymentMethodModel)
        .subscribe(
          response => {
            this.alertService.success('Payment method added successfully!', { keepAfterRouteChange: true, autoClose: true, });
            $('.jsCreatePaymentMethodModal').hide();
            this.selectedMethod(response);
          }
        );
  }

  deletePaymentMethod() :void {
    this.paymentService.deletePaymentMethod(this.paymentMethodModel)
        .subscribe(
          response => {
            this.alertService.success('Payment method deleted successfully!', { keepAfterRouteChange: true, autoClose: true, });
            $('.jsCreatePaymentMethodModal').hide();
            this.paymentMethodModel = {
              "id": null,
              "name": null
            };
            this.selectedMethod(null);
          }
        );
  }
}
