  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar user panel -->
      
      <!-- search form 
      <form action="#" method="get" class="sidebar-form">
        <div class="input-group">
          <input type="text" name="q" class="form-control" placeholder="Search...">
          <span class="input-group-btn">
                <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                  <i class="fa fa-search"></i>
                </button>
              </span>
        </div>
      </form>-->
      <!-- /.search form -->
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <nav id="sidebar">
        <ul class="sidebar-menu list-unstyled components">
          <p>{{ user.name }}</p>
          <small>{{ user.email }}</small>

          <li [ngClass]="{'active': router.url === '/dashboard'}">
            <a [routerLink] = "['/dashboard']"><i class="fa fa-home" aria-hidden="true"></i> <span>Dashboard</span></a>
          </li>
          <li [ngClass]="{'active': router.url === '/products'}" *ngIf="inventory">
            <a [routerLink] = "['/products']"><i class="fa fa-ticket" aria-hidden="true"></i> <span>Inventory</span></a>
          </li>
          <li *ngIf="payments || flightbookings">
            <a href="#salesSubmenu" *ngIf="mainRoute === 'sales'" data-toggle="collapse" aria-expanded="true"><i class="fa fa-address-book" aria-hidden="true"></i>  <span>Sales</span></a>
            <a href="#salesSubmenu" *ngIf="mainRoute !== 'sales'" data-toggle="collapse" aria-expanded="false"><i class="fa fa-address-book" aria-hidden="true"></i>  <span>Sales</span></a>
            <ul class="collapse list-unstyled" [ngClass]="{'in': mainRoute === 'sales'}" id="salesSubmenu">
              <li>
                <a *ngIf="flightbookings" [routerLink] = "['/bookings/flights']"> <span>Flight Bookings</span></a>
              </li>
              <li>
                <a *ngIf="payments" [routerLink] = "['/sales/payments']"> <span>Payments</span></a>
              </li>
            </ul>
          </li>
          <li *ngIf="customers">
            <a href="#partiesSubmenu" *ngIf="mainRoute === 'customers'" data-toggle="collapse" aria-expanded="true"><i class="fa fa-address-book" aria-hidden="true"></i>  <span>Parties</span></a>
            <a href="#partiesSubmenu" *ngIf="mainRoute !== 'customers'" data-toggle="collapse" aria-expanded="false"><i class="fa fa-address-book" aria-hidden="true"></i>  <span>Parties</span></a>
            <ul class="collapse list-unstyled" [ngClass]="{'in': mainRoute === 'customers'}" id="partiesSubmenu">
              <li>
                <a [routerLink] = "['/customers/only_customers']"> <span>Customers</span></a>
              </li>
              <li>
                <a [routerLink] = "['/customers/only_suppliers']"> <span>Suppliers</span></a>
              </li>
            </ul>
          </li>
          <li [ngClass]="{'active': router.url === '/agency'}" *ngIf="users">
            <a [routerLink] = "['/agency']"><i class="fa fa-users" aria-hidden="true"></i> <span>Users</span></a>
          </li>
          <li *ngIf="expenses">
            <a href="#pageSubmenu" *ngIf="mainRoute === 'expenses'" data-toggle="collapse" aria-expanded="true"><i class="fa fa-money" aria-hidden="true"></i> <span>Expenses</span></a>
            <a href="#pageSubmenu" *ngIf="mainRoute !== 'expenses'" data-toggle="collapse" aria-expanded="false"><i class="fa fa-money" aria-hidden="true"></i> <span>Expenses</span></a>
            <ul class="collapse list-unstyled" [ngClass]="{'in': mainRoute === 'expenses'}" id="pageSubmenu">
              <li>
                <a [routerLink] = "['/expenses']"> <span>Expenses</span></a>
              </li>
              <li>
                <a [routerLink] = "['/expenses/accountslist']"> <span>Accounts</span></a>
              </li>
            </ul>
          </li>
          <li *ngIf="reports">
            <a href="#reportSubmenu" *ngIf="mainRoute === 'reports'" data-toggle="collapse" aria-expanded="true"><i class="fa fa-table" aria-hidden="true"></i> <span>Reports</span></a>
            <a href="#reportSubmenu" *ngIf="mainRoute !== 'reports'" data-toggle="collapse" aria-expanded="false"><i class="fa fa-table" aria-hidden="true"></i> <span>Reports</span></a>
            <ul class="collapse list-unstyled" [ngClass]="{'in': mainRoute === 'reports'}" id="reportSubmenu">
              <li>
                <a [routerLink] = "['/reports']"> <span>Profit Report</span></a>
              </li>
              <li>
                <a [routerLink] = "['/reports/detail']"> <span>Detailed Profit Report</span></a>
              </li>
              <li>
                <a [routerLink] = "['/reports/payments']"> <span>Payment Report</span></a>
              </li>
            </ul>
          </li>
          <li [ngClass]="{'active': router.url === '/reports/inventory-report'}">
            <a [routerLink] = "['/reports/inventory-report']"><i class="fa fa-table" aria-hidden="true"></i> <span>Sales Report</span></a>
          </li>
        </ul>
      </nav>
      <ul class="sub-nav">
        <li class="" *ngIf="'admin' == authResponse.user.menuroles">
          <a class="" [routerLink] = "['/settings/agency/settings']">
            <span class="">Settings</span>
          </a>
        </li>
        <li class="">
          <a class="" [routerLink] = "['/settings/change/password']">
            <span class="">Change Password</span>
          </a>
        </li>
        <li class="">
          <a class="" (click)="logout()">
            <span class="">Logout</span>
          </a>
        </li>
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>