import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { CustomersService } from '../../services/customers.service';

declare var $: any;

@Component({
  selector: 'app-statement-filter',
  templateUrl: './statement-filter.component.html',
  styleUrls: ['./statement-filter.component.css']
})
export class StatementFilterComponent implements OnInit {

  @Input('customer')
    customer: any;
  @Input('selectorClass')
    selectorClass: any;

  filterModel = {
    "from": null,
    "to": null,
    "statement_type": 'full_statement',
  };

  generatePdfFilter = {
    "customer_id": null,
    "from_date": null,
    "to_date": null,
    "type": null,
    "send_to_customer": true,
    "additionalEmails": null,
    "remarks": null
  };

  constructor(
    private router: Router,
    private customerService: CustomersService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    
    if(this.selectorClass == undefined) {
      this.selectorClass = 'jsStatementModal';
    }
  }

  showStatement(action): void {
    let filter = [];
    if(this.filterModel.from && this.filterModel.to) {
      filter.push(`from=${this.filterModel.from}&to=${this.filterModel.to}`);
    }
    
    if(this.filterModel.statement_type) {
      filter.push(`type=${this.filterModel.statement_type}`);
    }
    
    let strFilter = '';
    if(filter.length > 0) {
      strFilter = '?'+filter.join("&");
    }
    if('generate' == action) {
      this.router.navigate([]).then(result => {  window.open(`customers/statement/${this.customer.id}${strFilter}`, '_blank'); });
    }

    if('email' == action) {
      this.generatePdfFilter.customer_id = this.customer.id;
      this.generatePdfFilter.from_date = this.filterModel.from;
      this.generatePdfFilter.to_date = this.filterModel.to;
      this.generatePdfFilter.type = this.filterModel.statement_type;

      this.customerService.loadPdfStatement(this.generatePdfFilter).subscribe(
        data => {
          this.alertService.success('Statement sent successfully!', { keepAfterRouteChange: true, autoClose: true }); 
          $('.'+this.selectorClass).hide();
          $('.'+this.selectorClass+' .jsSendStatementAlert').hide();
        }
      );
    }
  }

  setStatementType(event) : void {
    if(this.filterModel.statement_type == event.target['value']) {
      this.filterModel.statement_type = null;
    }

    this.filterModel.from = null;
    this.filterModel.to = null;

  }

  confirmSendStatement() {
    this.generatePdfFilter.additionalEmails = this.customer ? this.customer.alternate_emails : null;
    $('.jsSendStatementAlert').show();
  }
}
