import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  @Input('selectedAccount')
    selectedAccount: any = null;
  
  @Input('label')
    label: any = null;

  @Output() 
    onMethodSelected = new EventEmitter<any>();

  searchString: string;
  isAccountsListLoading: boolean;
  accountsList: any;
  selectedAccountId: number;
  accountModel: any = {
    "id": null,
    "name": null
  };

  methodSearchTextSubject = new Subject<string>();

  constructor(
    private accountService: AccountService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

    if(this.selectedAccount) {
      this.selectedAccountId = this.selectedAccount.id;
      this.searchString = this.selectedAccount.name;
    }

    this.methodSearchTextSubject.pipe(
      debounceTime(500),
      distinctUntilChanged())
          .subscribe(value => {
            console.log(value);
              this.isAccountsListLoading = true;
              this.prefillMethods(this.searchString, false);
      });
  }

  prefillMethods(text: any, isLoading: Boolean = true): void {

    this.accountService.getAccounts(text)
      .subscribe(accounts => {
        this.accountsList = accounts;
        this.isAccountsListLoading = false;
        $(document).ready(function(){
          $('.jsAutocompleteList').each(function(){
            $(this).css('width', $(this).parent().width());
          });
        });      
      });
  }

  selectAccount(account: any) {
    if(account) {
      this.selectedAccountId = account.id;
      this.searchString = account.name;
    } else {
      this.selectedAccountId =null;
      this.searchString = null;
    }
    
    this.onMethodSelected.emit(this.selectedAccountId);
  }

  openAddNewAccountForm(method = null): void {

    if(method)
      this.accountModel = method;
    else 
      this.accountModel = {
        "id": null,
        "name": null
      };
    $('.jsCreateAccountModal').show();
  }

  createAccount(): void {
    this.accountService.createAccount(this.accountModel)
        .subscribe(
          response => {
            this.alertService.success('Account added successfully!', { keepAfterRouteChange: true, autoClose: true, });
            $('.jsCreateAccountModal').hide();
            this.selectAccount(response);
          }
        );
  }

  deleteAccount() :void {
    this.accountService.deleteAccount(this.accountModel)
        .subscribe(
          response => {
            this.alertService.success('Account deleted successfully!', { keepAfterRouteChange: true, autoClose: true, });
            $('.jsCreateAccountModal').hide();
            this.accountModel = {
              "id": null,
              "name": null
            };
            this.selectAccount(null);
          }
        );
  }

}
