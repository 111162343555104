import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { GeneralMethodsService } from './general-methods.service';
import { APIHelperService } from './api-helper.service';
import { AccountModel,  AuthResponseModel, TokenModel, UserModel } from '../../account/account.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, debounceTime } from 'rxjs/operators';

@Injectable()
export class AuthService  {
  httpHeaders: any;
  //private authResponseSubject: BehaviorSubject<AuthResponseModel>;
  //authResponse: Observable<AuthResponseModel>;
  state: any;

  constructor(
    private generalMethodsService: GeneralMethodsService,
    private apiHelperService: APIHelperService,
    private router: Router,
    ) {
      this.state = this.router.routerState.snapshot;

      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
        })
      };
      //this.authResponseSubject = new BehaviorSubject<AuthResponseModel>(JSON.parse(localStorage.getItem('AuthResponse')));
      //this.authResponse = this.authResponseSubject.asObservable();
  }

  public getAuthResponse(): AuthResponseModel {
    let response = JSON.parse(localStorage.getItem('AuthResponse'));
    let authResponse =  new AuthResponseModel();
    if(response){
      authResponse =  {
        token : <TokenModel> response.token,
        user: <UserModel> response.user,
      }
    }
    return authResponse;
  }

  public authorize(AccountModel: AccountModel): Observable<AuthResponseModel> {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/auth/signin`, AccountModel, this.httpHeaders)
        .subscribe(
          authResponse => {
            localStorage.setItem('AuthResponse', JSON.stringify(authResponse));
            observer.next(authResponse);
            observer.complete();
          }            
        );
      });
  }
 
  public sendResetPasswordLink(AccountModel: AccountModel): Observable<AuthResponseModel> {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/auth/password/request`, AccountModel, this.httpHeaders)
        .subscribe(
          authResponse => {
            localStorage.setItem('AuthResponse', JSON.stringify(authResponse));
            observer.next(authResponse);
            observer.complete();
          }            
        );
      });
  }
 
  public resetPassword(resetPasswordModel): Observable<AuthResponseModel> {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/auth/password/reset`, resetPasswordModel, this.httpHeaders)
        .subscribe(
          authResponse => {
            localStorage.setItem('AuthResponse', JSON.stringify(authResponse));
            observer.next(authResponse);
            observer.complete();
          }            
        );
      });
  }

  public isUserLoggedIn(): boolean {  
    const authResponse = this.getAuthResponse();

    return authResponse && authResponse.token != null && authResponse.token.auth_token != null;
  }

  public logout(): void {
    localStorage.removeItem('AuthResponse');
    localStorage.removeItem('isActive');
    this.router.navigate(['/login']);
  }

  public saveAuthResponse(authResponse): void {
    localStorage.setItem('AuthResponse', JSON.stringify(authResponse));
  }
  public loadUsers(text: string, isLoading: Boolean = true): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/common/load-users`;
      this.apiHelperService.get(uri, this.httpHeaders, isLoading)
        .pipe(
          //debounceTime(500),  // WAIT for KEY STROKE.
          map(
            (response: any) => {
              let result = [];
              response.forEach(function (object) {
                result.push({'name': object.name, 'id': object.id })
              });  
              
              observer.next(result);
              observer.complete();       
            }
          ),          
        ).subscribe();
      }
    );      
  }

}
