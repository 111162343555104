import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel, UserModel } from '../../account/account.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { CustomerSearchModel, PasswordLessLoginModel, CustomersListModel, ApproveRevokeModel, CustomerModel, TransactionsListModel, ApproveRejectTransactionModel, ExportToExcelModel, BankTransferModel, DepositModel } from '../../customers/models/customers.model';

@Injectable()
export class SettingsService {
  httpHeaders: any;
  authResponse: AuthResponseModel;
  user: UserModel = new UserModel();
  customersList: CustomersListModel = new CustomersListModel();
  transactionsList: TransactionsListModel = new TransactionsListModel();
  agency: any;

  constructor(
    private apiHelperService: APIHelperService,
    private authService: AuthService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };

    this.authResponse = this.authService.getAuthResponse();
    this.user = this.authResponse.user != null ? this.authResponse.user : new UserModel();
  }
  public changePassword(url,data): Observable<any> {
    return new Observable((observer) => {
      let uri = `${environment.apiURI}/`+url;
      this.apiHelperService.post(uri, data, this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }
        );
      }
    );
  }

}

