<div class="row" style="margin-left: 1px;">
  <div class="col-lg-12">
    <div class="form-group">
      <label>Mode of Payment:</label>
      <input type="text" class="form-control add-able-search autocomplete-text" name="payment_method" [(ngModel)] = "searchString" (ngModelChange) = "this.methodSearchTextSubject.next($event)" (focus) = "this.methodSearchTextSubject.next($event)"/>
      <div class="list-loading" *ngIf = "isPaymentMethodListLoading">
          <i class="fa fa-circle-o-notch fa-spin"></i>
      </div>
      <div *ngIf = "paymentMethodsList" class="autocomplete-list jsAutocompleteList">
        <div class="autocomplete-item" (click) = "selectedMethod(null)">
          None
        </div>
          <div *ngFor = "let method of paymentMethodsList">
              <div class="autocomplete-item" (click) = "selectedMethod(method)">
                  <strong>{{method.name}}</strong><span (click) = "openAddNewPaymentMethodForm(method)" class="pull-right" style="color: '#7c1fa3'; font-size: 21px;margin-right:13px;margin-bottom: -1px;"><i class="fa fa-edit"></i></span>
              </div>
          </div>
      </div>
      <button type="button" class="btn btn-primary" (click) = "openAddNewPaymentMethodForm()" style="position: absolute; top: 25px; right: 8px; height: 61px; width: 61px;">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>


<div class="modal jsModal jsCreatePaymentMethodModal" style="top: 0; left: 0;">
    <div class="modal-dialog">
      <div class="modal-content">
        <form autocomplete="off" class="needs-validation" (ngSubmit)="createPaymentMethod()" ngNativeValidate >
          <div class="modal-header">
            <button type="button" class="close jsModalClose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Add Payment Method</h4>
          </div>
          <div class="modal-body">                        
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                    <label>Name:</label>
                    <input class="form-control" type="text" placeholder="Enter name" id="name" name="name" [(ngModel)] = "paymentMethodModel.name" required />                     
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button *ngIf="paymentMethodModel.id" type="button" class="btn btn-danger pull-left margin-right-0" data-dismiss="modal" (click) = "deletePaymentMethod()"><i aria-hidden="true" class="fa fa-trash"></i>Delete</button>
            <button type="button" class="btn btn-cancel margin-right-0 jsModalClose" data-dismiss="modal"><i aria-hidden="true" class="fa fa-times"></i> Cancel</button>
            <button type="submit" class="btn btn-green"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
          </div>
        </form>         
      </div>
    </div>
  </div>