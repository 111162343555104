import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { SubscriptionGuard } from './shared/guards/subscription.guard';


const productsModule = () => import('./products/products.module').then(x => x.ProductsModule);
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const dashboardModule = () => import('./dashboard/dashboard.module').then(x => x.DashboardModule);
const packagesModule = () => import('./packages/packages.module').then(x => x.PackagesModule);
const agencyModule = () => import('./agency/agency.module').then(x => x.AgencyModule);
const expensesModule = () => import('./expenses/expenses.module').then(x => x.ExpensesModule);
const bookingsModule = () => import('./bookings/bookings.module').then(x => x.BookingsModule);
const customersModule = () => import('./customers/customers.module').then(x => x.CustomersModule);
const unauthorizedModule = () => import('./unauthorized/unauthorized.module').then(x => x.UnauthorizedModule);
const reportsModule = () => import('./reports/reports.module').then(x => x.ReportsModule);
const settingsModule = () => import('./settings/settings.module').then(x => x.SettingsModule);
const subscriptionModule = () => import('./subscription/subscription.module').then(x => x.SubscriptionModule);
const salesModule = () => import('./sales/sales.module').then(x => x.SalesModule);

const routes: Routes = [
  { path: '', component: IndexComponent, canActivate: [AuthGuard],
      children: [
        { path: 'dashboard', loadChildren: dashboardModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'products', loadChildren: productsModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'packages', loadChildren: packagesModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'bookings', loadChildren: bookingsModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'customers', loadChildren: customersModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'agency', loadChildren: agencyModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'expenses', loadChildren: expensesModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'unauthorized', loadChildren: unauthorizedModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'reports', loadChildren: reportsModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'settings', loadChildren: settingsModule, canActivate: [AuthGuard, SubscriptionGuard] },
        { path: 'subscription', loadChildren: subscriptionModule, canActivate: [AuthGuard] },
        { path: 'sales', loadChildren: salesModule, canActivate: [AuthGuard, SubscriptionGuard] },
      ]
  },
  { path: 'login', loadChildren: accountModule },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
