export class PersonnelModel {
    id: string = null;
    name: string;
    category: string;
    description: string;
    components?: PersonnelComponentsModel;

    constructor() {
        this.components = new PersonnelComponentsModel();
    }
}

export class PersonnelComponentsModel {    
    hourly: string;
    fixed: string;
}





