import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { AuthResponseModel } from '../../account/account.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class SubscriptionService  {
  httpHeaders: any;
  state: any;

  constructor(
    private apiHelperService: APIHelperService,
    ) {
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        })
      };
  }

  public hasActiveSubscription(): Observable<any> {
    return new Observable((observer) => {
        let uri = `${environment.apiURI}/subscription/check`;
        this.apiHelperService.get(uri, this.httpHeaders)
          .subscribe(
            result => {
              observer.next(result.is_active);
              observer.complete();
            }
          );    
    });
  }
}
