export class BookingsListModel {
    bookings: any[] = [];
    total: number;
    current_page: number;
    from: number;
    to: number;
    per_page: number;
}

export class PNRModel {
    flight_booking_id: string;
    pnr: string;
    additional_emails: string;
    duplicate_ticket_id: any;
    bookingStatus: string;
    send_pnr_to_customer: boolean;
}
export class AmendmentModel {    
    flight_booking_id: string;
    type: string;   
    reason : string;
    passengers : any;
    remarks : string;   
    status:string;
    amount?: number;
}

export class ExportToExcelModel {
    start_date: string;
    end_date: string;
}

export class FlightBookingPessangersModel {
    customer_id: number;
    booked_by: string;
    price: number;
    infant_price: number = 1500;
    booking_given_by: string;
    booking_given_by_user: number;
    adults: AdultPassengerModel[] = [];
    infants: InfantPassengerModel[] = [];
    customer_account: number;
    published_with: string;
    baggage_checkin: string;
    baggage_cabin:string;
    constructor() {
        this.adults.push(new AdultPassengerModel());
        this.infants.push(new InfantPassengerModel());
    }
}
export class AdultPassengerModel {
    title: string;
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
    passenger_remarks?: string;
}
export class InfantPassengerModel {
    gender: string;
    name: string;
    dob: string;
}

export class StopModel {
    airport_id: string;
    stopAirportSearch: string;
    departure_date: string;
    departure_time: string;
    arrival_date: string;
    arrival_time: string;
    airline_code: string;
}

export class InstantBookingModel {
    adult: number = 1;
    infant: number = 0;
    booking_given_by: string;
    booking_given_by_user: number;
    adults: AdultPassengerModel[] = [];
    infants: InfantPassengerModel[] = [];
    customer_id: number;
    supplier_id: number;
    booked_by: string;
    bookingCount: number = 1;
    bookings: SingleBookingModel[] = [];
    supplier_account: number;
    customer_account: number;
    allow_past_booking: boolean = false;
    baggage_checkin: string;
    baggage_cabin: string;
}

export class SingleBookingModel {
    fromSearchString: string;
    from_airport_id: number;
    toSearchString: string;
    to_airport_id: number;
    from_terminal: any;
    to_terminal: any;
    airline_id: number;
    airline_code: string;
    departure_date: string;
    departure_time: string;
    arrival_date: string;
    arrival_time: string;
    departureDate: string;
    pnr: string;
    selling_price: string;
    cost_price: string;
    infant_price: number = 1500;
    stops: StopModel[] = [];
}

export class BookingSearch {
    pnr: string;
    booking_id: string;
    departure_date: string;
}

export class UpdateBookingModel {
    bookingId: string;
    supplier: any = null;
    supplier_id: number = null;
    price:string;
    infant_price:number = 1500;
    cost:number;
    update_timings_to_inventory:number;
    departure_date:string;
    departure_time:string;
    arrival_date:string;
    arrival_time:string;
    adults: AdultPassengerModel[] = [];
    infants: InfantPassengerModel[] = [];
    flight_number: string;
    total_cost: number;
    pnr: string;
    panelty: number;
    update_remarks: string;
}