import { LocationModel } from './locations.model';

export class ActivityModel {
    id: string = null;
    name: string;
    location: LocationModel = new LocationModel();
    description: string;
    inclusions: string;
    exclusions: string;
    needs_vehicle: string;
    hour_time: string = null;
    components?: ActivityComponentsModel;

    constructor() {
        this.components = new ActivityComponentsModel();
    }
}

export class ActivityComponentsModel {    
    hourly_per_person: string;
    hourly: string;
    fixed_per_person: string;
    fixed: string;
}





